<template>
  <div>
    <el-dialog title="实测包装信息导入" :visible.sync="visible" width="90%" :close-on-click-modal="false">
      <QueryForm
        v-model="queryForm"
        :query-btn-loading="tableLoading"
        @query="handleQueryDefault"
        @reset="handleQueryFormReset"
      >

        <el-form-item label="Style">
          <Select
            v-model="queryForm.styleIdList"
            api-key="styleList"
            clearable
            :configuration="{ key: 'id', label: 'styleName', value: 'id' }"
            multiple
          />
        </el-form-item>
        <el-form-item label="导入时间" :class="$i18n.locale">
          <el-date-picker
            v-model="queryForm.value"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item label="操作账户">
          <el-input
            v-model="queryForm.createByName"
          />
        </el-form-item>
        <el-form-item label="状态">
          <Select
            v-model="queryForm.statusList"
            :select-options="statusList"
            :configuration="{ key: 'value', label: 'label', value: 'value' }"
            clearable
            multiple
          />
        </el-form-item>
      </QueryForm>
      <el-button type="primary" @click="dialogVisible=true">导入</el-button>

      <el-divider content-position="left" class="mt-5">导入成功记录</el-divider>
      <el-table :loading="loading" :data="tableData" highlight-current-row style="width: 100%" max-height="400px">
        <el-table-column prop="packageImportCode" label="ID" min-width="120px" />
        <el-table-column prop="createTime" label="导入时间" min-width="120px" />
        <el-table-column
          prop="createByName"
          label="操作账户"
        />
        <el-table-column prop="styleName" label="Style" />
        <el-table-column prop="vendorName" label="供应商" />
        <el-table-column prop="statusI18" label="状态" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==3" type="text" @click="handleSpecial(scope.row.packageImportCode)">例外处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :pager="pager" class="mt-3" end @pagination="pagerUpdate" />

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
      </span>
      <el-dialog
        width="400px"
        title="例外处理"
        :close-on-click-modal="false"
        :visible.sync="dialogSpecialVisible"
        append-to-body
      >
        <el-form ref="form" :model="form">
          请输入例外准许通过此次实测包装信息的原因：
          <el-form-item class="mt-2" label="" prop="exceptionReason" :rules="{required:true,message:'必填',trigger:'blur'}">
            <el-input
              v-model="form.exceptionReason"
              type="textarea"
              :rows="2"
              :maxlength="256"
              show-word-limit
            />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogSpecialVisible = false">关闭</el-button>
          <el-button type="primary" @click="handleSubmit('form')">确定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
    <PackageDetail v-model="dialogVisible" @update="handleQuery" />
  </div>
</template>

<script>
import { recordPage, statusPage, exceptionHandler } from '@/api/package'
// import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
import PackageDetail from './PackageDetail'
import { omit } from 'lodash'

export default {
  components: { Select, QueryForm, Paging, PackageDetail },
  // mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogSpecialVisible: false,
      dialogVisible: false,
      receiptPlaceList: [],
      loading: false,
      tableData: [],
      queryForm: { value: [] },
      form: { exceptionReason: '', packageImportCode: '' },
      tableLoading: false,
      statusList: [],
      pager: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    params() {
      const { value = [] } = this.queryForm
      const [createTimeStart, createTimeEnd] = value || []
      return Object.assign(omit(this.queryForm, ['value']), { createTimeStart, createTimeEnd }, this.pager)
    }
  },
  watch: {
    'value'(val) {
      if (val) {
        this.handleQuery()
        this.queryStatusList()
      }
    },
    'dialogSpecialVisible'(val) {
      if (!val) {
        this.form.exceptionReason = ''
        this.$refs.form.resetFields()
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    async  queryStatusList() {
      const { datas } = await statusPage()
      this.statusList = []
      if (datas) {
        Object.keys(datas).map(item => this.statusList.push({ value: item, label: datas[item] }))
      }
    },
    handleSubmit(ref) {
      this.$refs[ref].validate(async valid => {
        if (valid) {
          await exceptionHandler(this.form)
          this.$message.success('操作成功')
          this.dialogSpecialVisible = false
          this.handleQueryDefault()
        }
      })
    },
    handleSpecial(packageImportCode) {
      this.form.packageImportCode = packageImportCode
      this.dialogSpecialVisible = true
    },
    handleQueryDefault() {
      this.pager.current = 1
      this.handleQuery()
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    async handleQuery() {
      try {
        this.loading = true
        // this.tableData = [{ status: 3, style: 11 }]
        const { datas: { pager, records }} = await recordPage(this.params)
        this.tableData = records
        this.pager = pager
      } finally {
        this.loading = false
      }
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
