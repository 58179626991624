export const detailColumns = [
  { label: '采购反馈到货日期', prop: 'vendorDeliveryFeedback' },
  { label: '操作人', prop: 'createByName' },
  { label: '操作时间', prop: 'createTime' },
  { label: '未准交原因', prop: 'deliveryDelayI18' },
  { label: 'LT不达标原因', slot: 'substandardI18' },
  { label: '备注', prop: 'deliveryRemark' }
]

export const ltColumns = [
  { label: 'LT不达标原因', prop: 'substandardI18' },
  { label: 'LT不达标备注', prop: 'substandardNote' },
  { label: '操作时间', prop: 'createTime' },
  { label: '操作人', prop: 'createByName' }
]

export const packageViewColumns = [
  { label: '错误消息', prop: 'errorMessage' },
  { label: 'Style', prop: 'style' },
  { label: '供应商', prop: 'vendorName' },
  { label: '尺码', prop: 'size' },
  { label: '外箱尺寸', prop: 'outerBoxSize' },
  { label: '外箱毛重', prop: 'outerBoxWeight' },
  { label: '鞋盒尺寸', prop: 'innerBoxSize' },
  { label: '鞋盒毛重', prop: 'innerBoxWeight' }
]
