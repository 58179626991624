<template>
  <div>
    <el-dialog
      title="发送邮件确认"
      :visible.sync="visible"
      width="500px"
      :close-on-click-modal="false"
    >
      <span>请确认是否给供应商发送取消邮件，点击确认进入邮件编辑页面</span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button @click="cancelDialogFormVisible = true;visible = false">不发送</el-button>
        <el-button type="primary" @click="confirmSend">确定发送</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="cancelDialogFormVisible"
      :close-on-click-modal="false"
      width="30%"
      @closed="cancelClosed"
    >
      <el-form
        ref="ruleForm"
        :rules="cancelrules"
        label-width="100px"
        class="demo-ruleForm"
        :model="ruleForm"
      >
        <el-form-item label="取消原因" prop="cancelReason">
          <el-input v-model="ruleForm.cancelReason" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelClosed">取 消</el-button>

        <el-button type="primary" @click="cancelOrderSure">确 定</el-button>
      </span>
    </el-dialog>
    <SendEmail v-model="dialogVisible" :title="'采购订单取消邮件'" :detail-datas="detailDatas" @handleSubmit="handleSubmit" />
  </div>
</template>

<script>
import { cancelPurchaseOrder, cancelOrderEmailInfo, cancelOrderSendEmail } from '@/api/scm-api'
import SendEmail from '@/components/SendEmail'
export default {
  components: { SendEmail },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      cancelDialogFormVisible: false,
      dialogVisible: false,
      ruleForm: {
        cancelReason: ''
      },
      cancelParams: {},
      cancelrules: {
        cancelReason: [
          { required: true, message: this.$t('page.required'), trigger: 'blur' }
        ]
      },
      detailDatas: {}
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },

  created() {

  },
  mounted() {
  },
  methods: {
    async confirmSend() {
      this.dialogVisible = true
      this.visible = false
      const { datas } = await cancelOrderEmailInfo(this.row.purchaseOrderCode)
      const receiverEmail = JSON.parse(datas.receiverEmail)?.join('；')
      const ccEmail = JSON.parse(datas.ccEmail)?.join('；')
      Object.assign(datas, { receiverEmail, ccEmail })
      this.detailDatas = datas
    },
    async handleSubmit(val) {
      const { receiverEmail, ccEmail, bccEmail } = val
      const params = {}
      Object.assign(params, val, { receiverEmail: JSON.stringify(receiverEmail?.split('；')), ccEmail: JSON.stringify(ccEmail?.split('；')), bccEmail: JSON.stringify(bccEmail?.split('；')) })
      await cancelOrderSendEmail(params)
      this.dialogVisible = false
      this.cancelDialogFormVisible = true
    },
    cancelOrderSure() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          const { cancelReason } = this.ruleForm
          const { cancelRole, purchaseOrderCode } = this.row
          Object.assign(this.cancelParams, { cancelReason, cancelRole, purchaseOrderCode })
          const { msg, code } = await cancelPurchaseOrder(this.cancelParams)
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          code === 0 ? (this.cancelDialogFormVisible = false) : ''
        }
      })
    },
    cancelClosed() {
      this.cancelDialogFormVisible = false
      this.$refs.ruleForm?.clearValidate()
      this.$refs.ruleForm?.resetFields()
    }
  }
}
</script>
