<template>
  <div>
    <el-dialog title="打印PDF鞋标" :visible.sync="visible" width="900px" :close-on-click-modal="false">
      <el-form ref="orderDetailForm" :model="orderDetailForm" label-width="130px" style="display: flex">
        <div class="el-lt" style="width: 85%">
          <el-row :span="24" class="row-input">
            <el-col :span="8">
              <el-form-item label="采购单号" :class="$i18n.locale">
                <el-input v-model="orderDetailForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商" :class="$i18n.locale">
                <el-select
                  v-model="orderDetailForm.vendorId"
                  filterable
                  clearable
                  :placeholder="$t('page.selectPlaceholder')"
                >
                  <el-option
                    v-for="item in vendorOptions"
                    :key="item.id"
                    :label="item.vendorName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="SKU/FNSKU/UPC" :class="$i18n.locale">
                <el-input v-model="orderDetailForm.fnSkuUpc" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="8">
              <el-form-item :label="$t('page.Style')">
                <el-select
                  v-model="orderDetailForm.style"
                  value-key="id"
                  clearable
                  filterable
                  @change="styleSelectChange"
                >
                  <el-option
                    v-for="item in styleOptions"
                    :key="item.styleName"
                    :label="item.styleName"
                    :value="item.styleName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('page.Color')">
                <el-select v-model="orderDetailForm.color" clearable filterable>
                  <el-option
                    v-for="item in colorOptions"
                    :key="item.id"
                    :label="item.colorName"
                    :value="item.colorName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('page.Size')">
                <el-select v-model="orderDetailForm.sizes" clearable filterable>
                  <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :span="24" class="row-input">
            <el-col :span="8">
              <el-form-item label="平台" :class="$i18n.locale">
                <el-input v-model="orderDetailForm.platform" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="站点" :class="$i18n.locale">
                <el-input v-model="orderDetailForm.site" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item label="创建日期">
                <el-date-picker
                  v-model="orderDetailForm.startCreateTime"
                  value-format="yyyy-MM-dd "
                  placeholder="开始时间"
                  type="date"
                />
                —
                <el-date-picker
                  v-model="orderDetailForm.endCreateTime"
                  value-format="yyyy-MM-dd "
                  type="date"
                  placeholder="结束时间"
                />
              </el-form-item>
            </el-col> -->
          </el-row>
        </div>
        <el-row class="el-rt" style="width: 25%">
          <el-col class="row-center">
            <el-button type="primary" :loading="DetailTableLoading" @click="queryDetailClick(queryParams)">{{ $t("page.search") }}</el-button>
            <el-button @click="handleReset()">{{ $t("page.reset") }}</el-button>
            <!-- <el-button type="text" @click="showRow=!showRow">{{ showRow ? $t("page.hide") : $t("page.expand") }} -->
            <!-- </el-button> -->
          </el-col>
        </el-row>
      </el-form>
      <vxe-table
        ref="orderDetailDataRef"
        v-loading="DetailTableLoading"
        max-height="400"
        align="center"
        highlight-hover-row
        resizable
        :data="orderDetailData"
      >
        <!-- @checkbox-all="shoesDialogSelectionChange"
        @checkbox-change="shoesDialogSelectionChange"
        <vxe-table-column type="checkbox" /> -->
        <vxe-table-column width="50" type="seq" title="序号" />
        <vxe-table-column field="purchaseOrderCode" title="采购订单" width="160" />
        <vxe-table-column field="vendorName" title="供应商" width="100" />
        <vxe-table-column field="platform" title="平台" width="90" />
        <vxe-table-column field="site" title="站点" width="90" />
        <vxe-table-column field="specification" title="规格型号" width="180">
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.specification" placement="top">
              <span>{{ row.specification }}</span>
            </el-tooltip>
          </template>
        </vxe-table-column>
        <vxe-table-column field="imgUrl" align="center" sortable title="图片" width="160">
          <template v-slot="{ row }">
            <img :src="row.imgUrl" alt="暂无图片" style="width: 50px; height: 50px">
          </template>
        </vxe-table-column>
        <vxe-table-column field="sku" title="sku" width="180">
          <template v-slot="{ row }">
            <el-tooltip class="item" effect="dark" :content="row.sku" placement="top">
              <span>{{ row.sku }}</span>
            </el-tooltip>
          </template>
        </vxe-table-column>
        <vxe-table-column field="fnsku" title="fnsku" width="160" />
        <vxe-table-column field="upc" title="upc" width="160" />
        <vxe-table-column field="cargoType" title="双/箱" width="80" />
        <vxe-table-column field="number" title="采购数量" width="100" />
      </vxe-table>
      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="$emit('input', false)">取消</el-button>
        <el-popover
          placement="top"
          width="160"
          trigger="click"
        >
          <p>请选择纸张大小</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="printShoesdata('2','a4')">A4</el-button>
            <el-button type="text" size="mini" @click="printShoesdata('2','10*5')">10*5</el-button>
          </div>
          <el-button slot="reference" type="primary" :loading="flagLoading" class="ml-2 mr-2">按外箱提交</el-button>
        </el-popover>
        <!-- <el-button type="primary" :loading="flagLoading" @click="printShoesdata(1)">按箱提交</el-button> -->
        <el-button type="primary" :loading="flagLoading" @click="printShoesdata('3')">按内盒提交</el-button>
        <el-button type="primary" :loading="flagLoading" @click="printInternal">打印内里标</el-button>
        <el-button type="primary" :loading="flagLoading" @click="printAll">打印全部标签</el-button>
      </el-row>
    </el-dialog>
    <!-- 打印pdf鞋标 -->
    <PrintShoesDialog ref="printShoesRef" print-key="shoes" :shoes="shoes" :page-size="pageSize" />
    <!-- 内盒打印  -->
    <PrintInShoesDialog ref="printInShoesRef" print-key="shoes" :shoes="shoes" />
  </div>
</template>

<script>
import PrintShoesDialog from './PrintShoesDialog'
import PrintInShoesDialog from './PrintInShoesDialog'
import { omit } from 'lodash'
import { listDetailByPrint, boxPrintShoesCode, printShoes, queryStyleList,
  queryColorList, queryVendorList,
  querySizeList, boxInsideLabel, boxPrintAll } from '@/api/scm-api'
export default {
  components: { PrintInShoesDialog, PrintShoesDialog },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    codes: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
      boxPrintShoesCode,
      printShoes,
      orderDetailData: [], DetailTableLoading: false,
      orderDetailForm: { },
      // showRow: false,
      shoesDialogSelectList: [],
      flagLoading: false,
      flag: 1,
      shoes: [],
      vendorOptions: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      pageSize: ''
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    api() {
      return this.flag === '3' ? boxPrintShoesCode : printShoes
    },
    queryParams() {
      return Object.assign({}, this.orderDetailForm, { purchaseOrderCode: this.codes })
    },
    printParams() {
      const idArray = []
      this.orderDetailData.map(e => {
        idArray.push(e.id)
      })
      const data = Object.assign({}, { ids: idArray.join(','), flag: this.flag })
      return this.flag === '3' ? omit(data, 'flag') : data
    }
  },
  watch: {
    'value'(val) {
      if (val) {
        this.queryDetailClick({ purchaseOrderCode: this.codes })
      } else {
        this.$nextTick(() => {
          Object.assign(this.orderDetailForm, this.$options.data.call(this).orderDetailForm)
        })
      }
    }
  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    this._queryStyleList()
    this._queryColorListByStyle()
    this._querySizeListByStyle()
  },
  methods: {
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      const id = this.styleOptions.find(item => item.styleName === value)?.id
      this._queryColorListByStyle(id)
      this._querySizeListByStyle(id)
    },
    // color信息获取
    async _queryColorListByStyle(styleId) {
      const { datas } = await queryColorList({ styleId })
      this.colorOptions = datas
    },
    // size信息获取
    async _querySizeListByStyle(styleId) {
      const { datas } = await querySizeList({ styleId })
      this.sizeOptions = datas
    },
    // 打印pdf鞋标
    printShoesdata(flag, pageSieze) {
      if (!this.orderDetailData.length) {
        this.$message({
          message: '至少存在一条明细',
          type: 'warning'
        })
        return
      }
      this.flagLoading = true
      this.flag = flag
      this.pageSize = pageSieze
      this._printShoes()
    },
    async _printShoes() {
      try {
        this.shoes = []
        const { datas, code } = await this.api(this.printParams)
        if (code === 0) {
          // 分po单打印循环
          var map = {}; var result = []
          for (var i = 0; i < datas.length; i++) {
            var ai = datas[i]
            if (!map[ai.purchaseOrderCode]) {
              result.push({
                purchaseOrderCode: ai.purchaseOrderCode,
                data: [ai]
              })
              map[ai.purchaseOrderCode] = ai
            } else {
              for (var j = 0; j < result.length; j++) {
                var dj = result[j]
                if (dj.purchaseOrderCode === ai.purchaseOrderCode) {
                  dj.data.push(ai)
                  break
                }
              }
            }
          }
          this.shoes = result.map(item => item.data)
          this.$nextTick(() => {
            if (this.shoes !== null) {
              if (this.flag === '3') {
                this.$refs.printInShoesRef.$emit('showDialog', true)
              } else {
                this.$refs.printShoesRef.$emit('showDialog', true)
              }
            }
          })
        }
      } finally {
        this.flagLoading = false
      }
    },
    handleReset() {
      this.orderDetailForm = this.$options.data.call(this).orderDetailForm
      this.queryDetailClick({ purchaseOrderCode: this.codes })
    },
    // shoesDialogSelectionChange({ records }) {
    //   this.shoesDialogSelectList = records
    // },
    async queryDetailClick(params) {
      try {
        this.DetailTableLoading = true
        const { datas } = await listDetailByPrint(params)
        this.orderDetailData = datas
      } finally {
        this.DetailTableLoading = false
      }
    },
    async printInternal() {
      const arr = this.codes.split(',')
      if (arr.length > 40) return this.$message.warning('最多不超过40个PO')
      try {
        this.flagLoading = true
        await boxInsideLabel(arr)
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
      } finally {
        this.flagLoading = false
      }
    },
    async printAll() {
      const arr = this.codes.split(',')
      if (arr.length > 40) return this.$message.warning('最多不超过40个PO')
      try {
        this.flagLoading = true
        await boxPrintAll(arr)
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
      } finally {
        this.flagLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
