<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="visible"
      width="500px"
      :close-on-click-modal="false"
    >
      <span>确认回滚请先填写回滚原因，确认后无法撤回</span>
      <el-divider />
      <el-form ref="ruleFormRef" :model="ruleForm" label-width="80px">

        <el-form-item label="回滚原因" required :rules="{required: true, message:'必填', trigger: 'blur' }" prop="rollbackReason">
          <el-input
            v-model="ruleForm.rollbackReason"
            type="textarea"
            maxlength="256"
            show-word-limit
            :autosize="{ minRows: 2}"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input',false)">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { purchaseOrderRollBack } from '@/api/scm-api'

export default {

  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      ruleForm: {
        rollbackReason: ''
      }
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  watch: {
    'visible'(val) {
      if (val) {
        this.$refs.ruleFormRef?.clearValidate()
        this.$refs.ruleFormRef?.resetFields()
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleSubmit() {
      this.$refs.ruleFormRef.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const { purchaseOrderCode } = this.row
            const { code, msg } = await purchaseOrderRollBack(Object.assign({}, { purchaseOrderCode }, this.ruleForm))
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$emit('input', false)
              this.$emit('refresh')
            }
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

