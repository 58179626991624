export default {
  OrderTagImport: {
    header: ['序号', '错误信息', '采购订单', '销售季节', '订单标签', '备注'],
    filterVal: ['no', 'errorMsgList', 'purchaseOrderCodeDisplay', 'saleSeasonDisplay', 'orderTagDisplay', 'remarkDisplay'],
    columns: [
      { label: '错误信息', prop: 'errorMsgList' },
      { label: '采购订单', prop: 'purchaseOrderCodeDisplay' },
      { label: '销售季节', prop: 'saleSeasonDisplay' },
      { label: '订单标签', prop: 'orderTagDisplay' },
      { label: '备注', prop: 'remarkDisplay' }
    ]
  }
}
