<template>
  <div>
    <el-dialog title="交期反馈" :visible.sync="visible" width="900px" :close-on-click-modal="false" @close="setDelayRule = false;">
      <el-form ref="formwarehous" :model="formwarehous" label-position="left">
        <el-form-item label="采购订单号">
          {{ row.purchaseOrderCode }}
        </el-form-item>
        <el-form-item label="供应商交期日期">
          <el-date-picker v-model="formwarehous.vendorDeliveryFeedback" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" @change="e=>dataChange(e,formwarehous)" />
        </el-form-item>
        <el-form-item
          label="未准交原因"
          label-width="110px"
          prop="deliveryDelay"
          :rules="[{ required: setDelayRule, message: '必填', trigger: 'change' }]"
        >
          <el-select
            v-model="formwarehous.deliveryDelay"
            filterable
            clearable
            :placeholder="$t('page.selectPlaceholder')"
            @change="select_status"
          >
            <el-option
              v-for="item in DelayReasonOptions"
              :key="item.dictValue"
              :label="item.dictNameJson['zh-CN']"
              :value="Number(item.dictValue)"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="LT不达标原因"
          label-width="110px"
          prop="substandardDict"
        >
          <!-- :rules="[{ required: setLtRule, message: '必填', trigger: 'change' }]" -->
          <el-select
            v-model="formwarehous.substandardDict"
            filterable
            clearable
            :placeholder="$t('page.selectPlaceholder')"
            @change="select_status"
          >
            <el-option
              v-for="item in LtReasonOptions"
              :key="item.dictValue"
              :label="item.dictNameJson['zh-CN']"
              :value="Number(item.dictValue)"
            />
          </el-select>
        </el-form-item>
        <el-row :span="24">
          <el-col :span="16">
            <el-form-item label="交期备注" label-width="110px">
              <el-input v-model="formwarehous.vendorDeliveryFeedbackRemark" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="Vendordatas" max-height="350px" style="width: 100%">
        <el-table-column width="60" type="index" align="center" />
        <el-table-column width="150" property="vendorDeliveryFeedback" label="采购反馈到货日期" align="center" />
        <el-table-column width="100" property="createByName" label="操作人" align="center" />
        <el-table-column width="155" property="createTime" label="操作时间" align="center" />
        <el-table-column width="155" label="未准交原因" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.deliveryDelay">{{ scope.row.deliveryDelayI18 }}</span>
          </template>
        </el-table-column>
        <el-table-column width="155" label="LT不达标原因" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.substandardI18&&scope.row.substandardDict">{{ scope.row.substandardI18 }}</span>
          </template>
        </el-table-column>
        <el-table-column width="155" property="deliveryRemark" label="备注" align="center" />
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false);setDelayRule=false">取 消</el-button>
        <el-button type="primary" :loading="upLoding" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import dayjs from 'dayjs'
import { getDelayReason, getLtReason } from '@/api/audit-api.js'
import { updateVendorDeliveryFeedback, VendorDeliveryFeedback } from '@/api/scm-api'
export default {
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      Vendordatas: [], upLoding: false, formwarehous: { vendorDeliveryFeedbackRemark: '',
        deliveryDelay: '', vendorDeliveryFeedback: '', substandardDict: '' },
      setDelayRule: false,
      setLtRule: false,
      DelayReasonOptions: [],
      LtReasonOptions: []
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    submitParams() {
      return Object.assign({}, { purchaseOrderCode: this.row.purchaseOrderCode }, this.formwarehous)
    }
  },
  watch: {
    'value'(val) {
      if (val) {
        this._VendorDeliveryFeedback(this.row.purchaseOrderCode)
        this.$refs.formwarehous.clearValidate()
      } else {
        this.$nextTick(() => {
          Object.assign(this.formwarehous, this.$options.data.call(this).formwarehous)
        })
      }
    }
  },
  created() {

  },
  mounted() {
    this._getLtReason()
    this._getDelayReason()
  },
  methods: {
    dataChange(e) {
      const { planDeliveryDate } = this.row
      if (e && planDeliveryDate) {
        const day = dayjs(e).diff(planDeliveryDate, 'day')
        this.setDelayRule = day > 0 || day < -10
        // 带出下方最近一次的未准交原因和时间
        if (this.setDelayRule && this.Vendordatas) {
          this.formwarehous.deliveryDelay = this.Vendordatas[0]?.deliveryDelay
          this.formwarehous.vendorDeliveryFeedbackRemark = this.Vendordatas[0]?.deliveryRemark
        } else {
          this.formwarehous.deliveryDelay = ''
          this.formwarehous.vendorDeliveryFeedbackRemark = ''
        }
      }
      // 0704取消LT不达标必填校验
      // , orderDate, ltDay
      // if (e && orderDate && ltDay) {
      //   const day = dayjs(e).diff(orderDate, 'day')
      //   this.setLtRule = day > ltDay
      // }
    },
    select_status() {
      this.$forceUpdate()
    },
    // 供应商交期反馈原因及时间
    async _VendorDeliveryFeedback(code) {
      const { datas } = await VendorDeliveryFeedback(code)
      // 按照时间先后顺序进行排序
      // const arr = datas.sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime())
      this.Vendordatas = datas
    },

    // 提交修改供应商交期时间
    submit() {
      this.$refs.formwarehous.validate((valid) => {
        if (!valid) return false
        this._updateVendorDeliveryFeedback(this.submitParams)
      })
    },
    async _updateVendorDeliveryFeedback(data) {
      try {
        this.upLoding = true
        const { code, msg } = await updateVendorDeliveryFeedback(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.upLoding = false
          this.vendordialogFormVisible = false
          this.$emit('input', false)
          this.$emit('refresh')
        }
      } finally {
        this.upLoding = false
      }
    },
    async _getDelayReason() {
      const { datas } = await getDelayReason()
      this.DelayReasonOptions = datas
    },
    async _getLtReason() {
      const { datas } = await getLtReason()
      this.LtReasonOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">
</style>
