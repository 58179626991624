<template>
  <div>
    <el-dialog
      title="批量导入"
      :visible.sync="visible"
      width="40%"
    >
      <el-form ref="form" v-model="form">
        <el-form-item label="文件上传：">
          <el-upload
            ref="uploadForm"
            :action="action"
            :accept="accept"
            :class="newClass"
            :http-request="uploadFile"
            :limit="limit"
            :show-file-list="showFileList"
            :file-list="fileList"
            :auto-upload="autoUpload"
            :on-change="fileChange"
            :on-remove="fileRemove"
            :before-upload="beforeUpload"
            :on-success="fileSuccess"
            :on-exceed="handleExceed"
          >
            <el-button type="primary">{{ importName }}</el-button>
          </el-upload>
        </el-form-item>
        <el-button type="text" @click="$emit('handleDownload')">导入模板下载</el-button>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" @click="handleImport">导入预览</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="detailDialog" width="90%" title="导入详情" append-to-body>

      <vxe-table
        ref="uploadTableDateRef"
        max-height="500px"
        align="center"
        :data="detailsData"
        :cell-style="cellStyle"
        :row-config="{ height: 80 }"
        :checkbox-config="{checkMethod:tableCheckboxConfig}"
        @checkbox-all="selectionChange"
        @checkbox-change="selectionChange"
      >
        <vxe-table-column type="checkbox" width="60" />
        <vxe-table-column type="index" width="60" title="序号" />
        <vxe-table-column field="errorMsg" title="错误信息" min-width="100" />
        <template v-for="(col, index) in importDetailsColumns">
          <!-- 操作列/自定义列 -->
          <vxe-table-column :key="index" :field="col.prop" :title="col.label" :width="col.width" align="center" />
        </template>
      </vxe-table>
      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="detailDialog=false">取消</el-button>
        <el-button
          type="primary"
          :loading="importSubmitLoading"
          :disabled="!multipleSelection.length"
          @click="submitDetailDatas"
        >
          提交
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    importName: {
      type: String,
      default: '文件上传'
    },
    accept: {
      type: String,
      default: '.xlsx, .xls'
    },
    limit: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    showFileDetails: {
      type: Boolean,
      default: false
    },
    uploadFile: {
      type: Function,
      default: () => { }
    },
    value: {
      type: Boolean,
      default: false
    },
    importDetailsColumns: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      default: ''
    },
    viewParams: {
      type: Object,
      default: () => {}
    },
    submitParams: {
      type: Object,
      default: () => {}
    },
    importViewApi: {
      type: Function,
      default: () => { }
    },
    importSubmitApi: {
      type: Function,
      default: () => { }
    }
  },
  data() {
    return {
      detailsData: [],
      fileList: [],
      file: '',
      multipleSelection: [],
      detailDialog: false,
      form: {},
      importSubmitLoading: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    newClass() {
      if (this.showFileList) {
        return 'avatar-uploader'
      } else {
        return 'el-button padding-none inline '
      }
    },
    viewImportParams() {
      const formDataParams = new FormData()
      // const form = Object.assign({}, { file: this.file }, this.viewParams)
      // Object.keys(form).forEach((key) => formDataParams.set([key], this.form[key]))
      formDataParams.set('file', this.file)
      return formDataParams
    },
    submitImportParams() {
      if (this.submitParams && Object.keys(this.submitParams).length) {
        return Object.assign(this.submitParams, { list: this.multipleSelection })
      } else return this.multipleSelection
    }

  },
  watch: {
    'value'(val) {
      if (!val) {
        this.$refs.uploadForm?.clearFiles()
        this.file = ''
        this.fileList = []
        this.multipleSelection = []
        this.detailsData = []
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    cellStyle({ row }) {
      if (row.errorMsg) {
        return {
          color: 'red'
        }
      }
    },
    handleExceed(files, fileList) {
      this.$refs.uploadForm.clearFiles()
      this.fileList = fileList.slice(-1)
      const file = files[0]
      this.$refs.uploadForm.handleStart(file)
      this.$emit('handleExceed', { files, fileList })
    },
    fileSuccess(files, fileList) {
      this.$emit('handleSuccess', { files, fileList })
    },
    tableCheckboxConfig({ row }) {
      if (row.errorMsg) {
        return false
      } else {
        return true
      }
    },
    selectionChange({ records }) {
      this.multipleSelection = records
      this.$emit('getSelectionChange', records)
    },
    closeDetailDialog() {
      this.$emit('input', false)
    },
    async submitDetailDatas() {
      try {
        if (this.multipleSelection.some(item => item.errorMsg)) {
          this.$message.warning('列表中有错误信息不允许提交')
          return
        }
        this.importSubmitLoading = true
        await this.importSubmitApi(this.submitImportParams)
        this.$notify({
          title: '操作成功',
          message: '操作成功',
          type: 'success'
        })
        this.detailDialog = false
        this.$emit('input', false)
        this.$emit('update')
      } finally {
        this.importSubmitLoading = false
      }
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.beforeUpload(file.raw)) {
        this.$emit('getFile', { file: file.raw, fileList })
      } else {
        this.file = ''
        this.fileList = []
        this.$emit('getFile', { file: '', fileList: [] })
      }
    },
    fileRemove() {
      this.file = ''
      this.fileList = []
      this.$emit('removeFile', '')
    },
    closeImportDialog() {
      this.importDialog = false
    },
    clearFile() {
      this.file = ''
      this.fileList = []
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.size
      if (!isLt2M) {
        this.$message.error(`上传失败，文件需小于${this.size}M`)
        return false
      }
      const isType = this.types.includes(file.name.split('.').pop())
      if (this.types.length && !isType) {
        this.$message.error('请上传正确格式文件!')
        return false
      }
      return true
    },
    async  handleImport() {
      if (!this.file) return this.$message.warning('暂无可导入的文件')
      const res = await this.importViewApi(this.viewImportParams)
      this.detailsData = res.data || res.datas
      this.detailsData.length ? this.detailDialog = true : this.$message.warning('暂无可预览数据')
    }
  }
}
</script>

<style scoped lang="scss">
.inline {
  display: inline-block !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
