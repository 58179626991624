import request from '@/utils/scm-request'

// 采购订单待办事项

// 分页查询
export function getBacklogApi(data, pager) {
  return request({
    url: `scm-purchase/order/backlog/page?size=${pager.size}&current=${pager.current}`,
    method: 'post',
    data
  })
}

// 获取数量
export function getBacklogCount(params) {
  return request({
    url: 'scm-purchase/order/backlog/userWaitCount',
    method: 'get',
    params
  })
}

export function delBacklog(data) {
  return request({
    url: 'scm-purchase/order/backlog/confirmFinish',
    method: 'delete',
    data
  })
}

