<template>
  <div>
    <el-dialog title="变更送货信息" :visible.sync="visible" width="70%" :close-on-click-modal="false">
      <el-table :loading="loading" :data="tableData" highlight-current-row style="width: 100%">
        <el-table-column type="index" width="80" label="序号" />
        <el-table-column prop="purchaseOrderCode" label="采购订单" />
        <el-table-column prop="vendorName" label="供应商" />
        <el-table-column>
          <template slot="header">送货方式
            <el-button type="text" @click="handleOneClick('method',tableData[0])">一键赋值</el-button>
          </template>
          <template slot-scope="scope">
            <Select
              v-model="scope.row.deliveryMethod"
              :select-options="_getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
              :configuration="{ key: 'val', label: 'label', value: 'val' }"
              clearable
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">收货地点
            <el-button type="text" @click="handleOneClick('place',tableData[0])">一键赋值</el-button>
          </template>
          <template slot-scope="scope">
            <!-- <Select
              v-model="scope.row.receiptPlaceCode"
              :query-flag="queryFlag"
              api-key="getWarehouse"
              clearable
              @responseData="val=>receiptPlaceList=val"
            /> -->
            <el-select
              v-model="scope.row.receiptPlaceCode"
              filterable
              clearable
              :placeholder="$t('page.selectPlaceholder')"
            >
              <el-option
                v-for="item in receiptPlaceList"
                :key="item.sourceWarehouseCode"
                :label="item.warehouseName"
                :value="item.sourceWarehouseCode"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="handleSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { changeDeliveryInfoView, changeDeliveryInfo } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { getWarehouse } from '@/api/listSelection'
export default {
  components: { Select },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      receiptPlaceList: [],
      loading: false,
      tableData: [],
      submitLoading: false,
      queryFlag: false
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(val) {
      if (val) {
        this.handleDetail()
        this.queryWarehouse()
        this.queryFlag = true
      } else {
        this.queryFlag = false
      }
    }
  },
  methods: {
    async queryWarehouse() {
      const { datas } = await getWarehouse()
      this.receiptPlaceList = datas
    },
    handleOneClick(type, { deliveryMethod, receiptPlaceCode }) {
      if (type === 'method') {
        this.tableData = this.tableData.map(item => { return { ...item, deliveryMethod } })
      } else {
        this.tableData = this.tableData.map(item => { return { ...item, receiptPlaceCode } })
      }
    },
    handleSubmit() {
      if (this.tableData.some(item => !item.receiptPlaceCode || !item.deliveryMethod)) {
        return this.$message.warning('请选择收货方式和收货地点')
      }
      this.tableData = this.tableData.map(item => {
        return { ...item,
          receiptPlaceName: this.receiptPlaceList.find(e => e.sourceWarehouseCode === item.receiptPlaceCode)?.warehouseName }
      })
      if (this.tableData.some(item => !item.receiptPlaceName)) return this.$message.warning('存在异常收货地点，请核对！')

      this.$confirm('提交后无法撤销，是否确认修改 ! ', this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        this.submit()
      })
    },
    async submit() {
      try {
        this.submitLoading = true
        await changeDeliveryInfo(this.tableData)
        this.$notify({
          title: '操作成功',
          message: '操作成功',
          type: 'success'
        })
        this.$emit('input', false)
        this.$emit('refresh')
      } finally {
        this.submitLoading = false
      }
    },
    async handleDetail() {
      try {
        this.loading = true
        const purchaseOrderCodeList = []
        this.list.map(item => purchaseOrderCodeList.push(item.purchaseOrderCode))
        const { datas } = await changeDeliveryInfoView({ purchaseOrderCodeList })
        this.tableData = datas.map(item => { return { ...item, deliveryMethod: item.deliveryMethod ? String(item.deliveryMethod) : '' } })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
