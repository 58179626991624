<template>
  <div>
    <el-dialog
      title="待办事项清单"
      :visible.sync="visible"
      width="90%"
      :close-on-click-modal="false"
      @close="closed"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待处理" name="first" />
        <el-tab-pane label="已完成" name="second" />
      </el-tabs>
      <QueryForm
        v-model="queryForm"
        :query-btn-loading="tableLoading"
        @query="handleQuery"
        @reset="handleQueryFormReset"
      >
        <el-form-item label="采购订单号" :class="$i18n.locale">
          <el-input
            v-model="queryForm.purchaseOrderCode"
            placeholder="多个以换行符分隔"
            type="textarea"
            autosize
            resize="none"
          />
        </el-form-item>
        <el-form-item label="Style">
          <Select
            v-model="queryForm.styleNameList"
            api-key="styleList"
            clearable
            :configuration="{ key: 'id', label: 'styleName', value: 'styleName' }"
            multiple
          />
        </el-form-item>
        <el-form-item label="供应商">
          <Select
            v-model="queryForm.vendorIdList"
            api-key="vendorList"
            clearable
            multiple
          />
        </el-form-item>
      </QueryForm>

      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        max-height="400px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column type="index" label="序号" width="50" align="center" />
        <el-table-column prop="backlogTypeDictI18" label="代办类型" width="140" align="center" />
        <el-table-column prop="stateDictI18" label="状态" width="80" align="center" />
        <el-table-column prop="purchaseOrderCode" label="采购订单号" width="180" align="center" />
        <el-table-column prop="styleName" label="Style" width="120" align="center" />
        <el-table-column prop="vendorName" label="供应商" width="120" align="center" />
        <el-table-column prop="backlogDescription" label="事项说明" align="center" show-overflow-tooltip />
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.stateDict==='WAITING'" type="text" @click="handleBacklog(scope)">处理待办</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :pager="pager" class="mt-3" end @pagination="pagerUpdate" />

      <div style="margin-top:10px; text-align:center">
        <el-button @click="visible = false;closed()">关闭</el-button>
        <el-button v-if="activeName === 'first'" type="primary" :loading="submitLoading" @click="toSubmit">确认完成</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
import { getBacklogApi, delBacklog } from '@/api/purchaseBacklog'
export default {
  components: {
    QueryForm,
    Select,
    Paging
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: 'first',
      loading: false,
      tableData: [],
      multipleSelection: [],
      tableLoading: false,
      queryForm: { stateDictList: ['WAITING'] },
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      submitLoading: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    params() {
      const { stateDictList, purchaseOrderCode } = this.queryForm
      const purchaseOrderCodeList = purchaseOrderCode ? this.preText(purchaseOrderCode).split(/[,]/) : []
      return Object.assign({}, this.queryForm,
        { purchaseOrderCodeList, stateDictList })
    }
  },
  mounted() {
    this.handleClick()
  },
  methods: {
    handleClick() {
      this.queryForm = this.$options.data.call(this).queryForm
      if (this.activeName === 'second') {
        this.queryForm.stateDictList = ['FINISH']
      } else {
        this.queryForm.stateDictList = ['WAITING']
      }
      this.handleQuery()
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    async handleQuery() {
      try {
        this.loading = true
        this.tableLoading = true
        const { datas: { pager, records }} = await getBacklogApi(this.params, this.pager)
        this.tableData = records
        this.pager = pager
      } finally {
        this.loading = false
        this.tableLoading = false
      }
    },
    handleQueryFormReset() {
      this.queryForm.purchaseOrderCode = ''
      this.queryForm.styleNameList = []
      this.queryForm.vendorIdList = []
      this.handleQuery()
    },
    preText(pretext) {
      const arr = []
      const array = pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',').replace(/\s/g, '&nbsp;').split(',') || []
      array.map(item => {
        if (item) {
          arr.push(item)
        }
      })
      return arr.join(',')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    toSubmit() {
      try {
        this.submitLoading = true
        if (this.multipleSelection.length <= 0) return this.$message.warning('至少选择一行数据')
        this.$confirm('请确认所选择的待办事项均已完成，该操作无法撤销！', this.$t('page.Prompt'), {
          confirmButtonText: this.$t('title.confirm'),
          cancelButtonText: this.$t('title.cancel'),
          type: 'warning'
        }).then(async() => {
          const idList = this.multipleSelection.map(item => item.id)
          await delBacklog(idList)
          this.$notify({
            message: '操作成功',
            type: 'success'
          })
          this.$emit('refresh')
          this.$emit('update')
          this.handleQuery()
          this.closed()
          this.$emit('input', false)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } finally {
        this.submitLoading = false
      }
    },
    handleBacklog({ row }) {
      const { purchaseOrderCode, purchaseOrderContractCode } = row
      if (row.waitingPage === '采购订单合同') {
        const routeData = this.$router.resolve({
          name: 'OrderContract',
          append: true,
          query: { isPurchase: true, purchaseOrderContractCode }
        })
        window.open(routeData.href, '_blank')
      } else {
        const routeData = this.$router.resolve({
          path: '../contract/supplementarycontract',
          append: true,
          query: { isPurchase: true, purchaseOrderCode, purchaseOrderContractCode }
        })
        window.open(routeData.href, '_blank')
      }
    },
    closed() {
      this.activeName = 'first'
      this.pager.current = 1
      this.pager.size = 20
      this.handleClick()
    }
  }
}
</script>

<style>

</style>
