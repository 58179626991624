<template>
  <div>
    <el-dialog
      title="批量导入"
      :visible.sync="visible"
      width="80%"
      :close-on-click-modal="false"
      @close="closed"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="上传文件" />
        <el-step title="数据预览" />
        <el-step title="导入数据" />
      </el-steps>

      <main class="upload-order-content">
        <section v-show="active === 0" class="import-orders">
          <div class="download-template">
            <h3 class="my-2">填写导入数据信息</h3>
            <small>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</small>
            <el-button
              class="mt-2"
              type="text"
              :loading="downloadLoding"
              @click="handleTemplate"
            >下载模板</el-button>
          </div>
          <el-divider />
          <div>
            <h3 class="my-2">上传填好的信息表</h3>
            <small>文件后缀名必须为xls
              或xlsx（即Excel格式），文件大小不得大于10M，最多不超过10000条数据</small>
            <ImportFile
              ref="uploadRef"
              :limit="1"
              class="mt-8px"
              :auto-upload="false"
              :upload-file="uploadPlanFile"
              accept=".xlsx, .xls"
              :types="['xls', 'xlsx']"
              :show-file-list="true"
              button-type="text"
              import-name="上传文件"
              @getFile="({file:val})=>file=val"
              @removeFile="handleFileRemove"
            />
          </div>
          <el-divider />

          <div class="download-template1">
            <h3 class="my-2">特别提示</h3>
            <small>导入过程中如发现个别数据校验不通过，则全量回滚修正后再重新操作导入</small>
          </div>
        </section>
        <section v-show="active === 1" class="import-orders">
          <div class="upload-error">
            <div class="upload-error--item">
              <span class="my-2">正常数量条数：</span>
              <el-link type="success" class="success-account" :underline="false">
                {{ tableInfo.errorResult.successAccount || 0 }} 条
              </el-link>
            </div>
            <div class="upload-error--item">
              <span class="my-2">异常数量条数：</span>
              <el-link type="danger" class="error-account mr-2" :underline="false">
                {{ tableInfo.errorResult.errorAccount || 0 }} 条
              </el-link>
              <ElButton v-show="tableInfo.errorResult.errorAccount" type="text" :loading="errorLoading" @click="handleExport">下载异常数据详情</ElButton>
            </div>
          </div>
          <el-divider />

          <Table
            :cell-style="cellStyle"
            max-height="600px"
            align="center"
            :table-data="tableInfo.defaultList"
            :columns="ColumnsMapList[columnsKey].columns"
          />
          <Paging :page-sizes="[10, 20, 40, 100]" :pager="pager" end @pagination="pagerUpdate" />
        </section>
        <section v-show="active === 2" class="data-review">
          <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage" />
          <p
            v-if="percentage"
            class="mt-16px"
            style="text-align: center"
          >正在导入数据，请勿关闭或刷新页面</p>
        </section>
        <section v-show="active === 3" style="text-align: center">
          <el-result icon="success" title="批量导入完成" :sub-title="`您已成功导入${tableInfo.tableList.length}条数据`" />
        </section>
      </main>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-show="active === 0" @click="closed">取消</el-button>
          <el-button v-show="active === 1" @click="handleToReview('previous')">上一步</el-button>
          <el-button
            v-show="active === 0"
            type="primary"
            :loading="loading"
            @click="handleToReview('next')"
          >下一步</el-button>
          <el-button
            v-show="active === 1"
            type="primary"
            :loading="loading"
            @click="handleToReview('submit')"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ImportFile from '@/components/ImportFile'
import Paging from '@/components/Pagination'
import Table from '@/components/Table'
import { trackDialogEvent, handleBreadTitle } from '@/utils/monitor'
import ColumnsMapList from './ColumnsMapList'
import { downloads } from '@/utils'
import { findVendorDownloadUrl } from '@/api/scm-api'
import { isString, isArray } from '@/utils/validate'

export default {
  name: 'UploadExcel',
  components: { ImportFile, Paging, Table },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    columnsKey: {
      type: String,
      default: ''
    },
    templateKey: {
      type: String,
      default: ''
    },
    templateUrl: {
      type: String,
      default: ''
    },
    importFileView: {
      type: Function,
      default: () => { }
    },
    importCommit: {
      type: Function,
      default: () => { }
    },
    downloadTemplateApi: {
      type: Function,
      default: findVendorDownloadUrl
    },
    downloadTemplateParams: {
      type: String,
      default: ''
    },
    errorMessageKey: {
      type: String,
      default: 'errorMessage'
    }

  },
  data() {
    return {
      ColumnsMapList,
      pager: { size: 20, current: 1, total: 0 },
      multipleTable: [],
      file: '',
      fileList: [],
      active: 0,
      downloadLoding: false,
      loading: false,
      errorLoading: false,
      percentage: 0,
      importError: false,
      tableInfo: {
        defaultList: [],
        tableList: [],
        errorResult: {
          successAccount: 0,
          errorAccount: 0
        }
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.file = ''
        this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      } else {
        trackDialogEvent(handleBreadTitle(this.$route) + '批量导入')
      }
    },
    'active'(val) {
      if (val !== 2) {
        this.importError = false
      }
    }
  },
  mounted() {
  },
  methods: {
    handleSuccessCount(value) {
      return (isString(value) && !value) || (isArray(value) && !value.length)
    },
    async handleTemplate() {
      try {
        this.downloadLoding = true
        if (this.templateUrl) return window.open(this.templateUrl, '_blank')
        const { datas } = await this.downloadTemplateApi(this.downloadTemplateParams)
        downloads(datas)
      } finally {
        this.downloadLoding = false
      }
    },
    pagerUpdate(val) {
      Object.assign(this.pager, val)
      const { current, size } = this.pager
      this.tableInfo.defaultList = this.tableInfo.tableList.slice((current - 1) * size, current * size)
    },
    handleFileRemove() {
      this.$refs.uploadRef.$refs.uploadForm.clearFiles()
    },
    async uploadPlanFile() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)
        const { code, datas } = await this.importFileView(formData)
        if (code === 0) {
          this.pager.current = 1
          this.tableInfo.tableList = datas
          const { current, size } = this.pager
          this.pager.total = datas.length
          this.tableInfo.defaultList = datas.slice((current - 1) * size, current * size)
          // 判断item[errorMessageKey]字符串或者是数组
          const successAccount = this.tableInfo.tableList.filter(item => !item[this.errorMessageKey] || this.handleSuccessCount(item[this.errorMessageKey]))?.length
          Object.assign(this.tableInfo.errorResult, {
            successAccount,
            errorAccount: this.tableInfo.tableList.length - successAccount
          })
          this.file = ''
          this.fileList = []
          this.active++
        }
      } finally {
        this.loading = false
        this.$refs.upload.$refs.uploadForm.clearFiles()
      }
    },
    handleToReview(type) {
      this.currentStatus(type)
    },
    closed() {
      this.file = ''
      this.active = 0
      this.$emit('input', false)
    },
    currentStatus(type) {
      if (type === 'next') {
        if (this.active === 0) {
          if (!this.file) return this.$message.warning('请上传文件')
          this.$refs.uploadRef.$refs.uploadForm.submit()
        }
      }
      if (type === 'previous') {
        this.active--
      }
      if (type === 'submit') {
        if (this.tableInfo.tableList.some((item) => this.handleErrorCount(item[this.errorMessageKey]))) { return this.$message.warning('当前预览数据存在错误，无法提交') }
        this.handleImport()
        this.active = 2
      }
      if (type === 'success') {
        this.active = 3
        this.$emit('refresh')
      }
    },
    async handleImport() {
      try {
        this.loading = true
        this.active = 3
        const { tableList } = this.tableInfo
        const { code } = await this.importCommit(tableList)
        if (code === 0) {
          this.percentage = 100
          this.currentStatus('success')
        }
      } catch (e) {
        this.percentage = 0
        this.importError = true
      } finally {
        this.loading = false
      }
    },
    // 下载错误信息表格
    async handleExport() {
      try {
        this.errorLoading = true
        const { filterVal, header } = ColumnsMapList[this.columnsKey]
        console.log('filterVal, header: ', filterVal, header)
        import('@/vendor/Export2Excel').then((excel) => {
          const data = this.formatJson(filterVal, this.tableInfo.tableList)
          excel.export_json_to_excel({
            header,
            data,
            filename: `异常数据列表`
          })
          setTimeout(() => {
            this.errorLoading = false
          }, 1000)
        })
      } catch (err) {
        this.errorLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        } else {
          return v[j]
        }
      }))
    },
    handleErrorCount(value) {
      return (isString(value) && value) || (isArray(value) && value.length)
    },
    cellStyle({ row, column }) {
      const value = row[this.errorMessageKey]
      if (this.handleErrorCount(value)) {
        return {
          color: 'red'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upload-dialog /deep/ {
    .el-step__title.is-success, .el-step__head.is-success , .el-step__description.is-success,
    .el-step__title.is-process, .el-step__head.is-process , .el-step__description.is-process{
    color: #1890ff;
    border-color: #1890ff;
  }
}
.upload-order-content {
  width: 90%;
  padding: 50px 0px;
  margin: 0 auto;

  .download-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .download-template1 {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    align-items: flex-start;
    background: rgb(255, 245, 230);
  }

  .upload-error--item {
    display: flex;
    align-items: center;
  }

  .upload-order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .el-upload--text {
      order: 2;
    }

    .el-upload__tip {
      order: 1;
    }

    .el-upload-list.el-upload-list--text {
      order: 3;
    }
  }
}
</style>

.
