<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="showPrintShoesDialog"
      fullscreen
      class="print"
      @open="show()"
      @close="closeDialog"
    >
      <div v-for="(item,index) in shoes" :id="`PDF${item[0].purchaseOrderCode}`" :key="index">
        <div ref="printShoesRef" class="ml-0 divide-inside">
          <el-row>
            <el-col
              v-for="(row, idx) in item"
              :key="idx"
              :style="{padding:pageSize==='a4'? '30px 0':'0'}"
              class="mmt-table-row"
              :span="pageSize==='a4'?12:24"
              :class="pageSize==='a4'?'a4':'mini-size'"
              :offset="pageSize==='a4'?5:0"
            >
              <div v-if="row.style.length>9" style="display: flex;width:460px;">
                <div class="style-class">{{ row.style }}</div>
              </div>
              <div v-else style="display: flex;width:460px;">
                <div
                  style="font-size: 35px;font-weight: 1000;font-family: 'Microsoft YaHei';line-height:1;margin-left:150px;"
                >
                  {{ row.style }}</div>
              </div>

              <div style="display: flex">
                <div>
                  <div style="width: 150px; height: 150px; ">
                    <img style="width: 100%; height: 150px" :src="row.imgUrl">
                  </div>
                </div>
                <div style="width: 135px">
                  <span style="display: block; text-align: center; margin: 8px 0 0 0">
                    <vue-qr
                      class="imgQr"
                      :text="row.purchaseOrderCode +'|' +row.shoeBatchNo +'|' +row.upc"
                      :size="200"
                      :margin="5"
                    />
                  </span>
                </div>
                <div style="font-size:18px;line-height:8px;font-weight:500;margin-left:4px;">
                  <!-- 欧楦 -->
                  <template v-if="row.shoeTreeStandard==2">
                    <div
                      class="size special-size show-margin"
                      :style="{backgroundColor:`${row.sizeColor}`,color:row.sizeColor?'#fff':'#000',textAlign:'left'}"
                    >
                      <div style="text-align:left;">EUR:{{ row.sizeEUR }}</div>
                    </div>
                    <div class="show-border div-specail-style">US:{{ row.sizeUS }}</div>
                  </template>
                  <!-- 美楦 -->
                  <template v-else>
                    <!-- 特殊style换行展示 -->
                    <div
                      v-if="isSpecialStyle(row.sizeUS)"
                      class="size  mb-0"
                      :style="{backgroundColor:`${row.sizeColor}`,color:row.sizeColor?'#fff':'#000',textAlign:'left'}"
                    >
                      <div>US:</div>
                      <div>{{ row.sizeUS }}</div>
                    </div>
                    <div
                      v-else
                      class="size special-size  mb-0 show-margin"
                      :style="{backgroundColor:`${row.sizeColor}`,color:row.sizeColor?'#fff':'#000',textAlign:'left'}"
                    >
                      US:{{ row.sizeUS }}
                    </div>
                    <div :class="isSpecialStyle(row.sizeUS)?'div-style':'div-specail-style'" class="show-border">EUR:{{
                      row.sizeEUR }}</div>
                  </template>
                  <div class="div-specail-style">UK:{{ row.sizeUK }}</div>
                  <div class="div-specail-style">JPN:{{ row.sizeJPN }}</div>
                </div>
              </div>
              <div :class="row.color.length>13?'colors':'short-color'">{{ row.color }}</div>
              <div class="code">
                {{ row.purchaseOrderCode }}|{{ row.shoeBatchNo }}|{{ row.upc }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
    <div
      class="pdf-header"
      style="font-weight: bold; padding:15px 8px; width: 100%; border-bottom: 1px solid rgba(0, 0, 0, 0.85); color: rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;"
    >
      页头
    </div>
    <div
      class="pdf-footer"
      style="font-weight: bold; padding: 15px 8px; width: 100%; border-top: 1px solid rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;"
    >
      <div style="display: flex; justify-content: center; align-items: center; padding-top: 5px;">
        我是页尾
      </div>
      <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
        第<div class="pdf-footer-page" />页 / 第<div class="pdf-footer-page-count" />页
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  name: 'PrintShoesDialog',
  components: {
    vueQr
  },
  props: {
    shoes: {
      type: Array,
      default() {
        return {

        }
      }
    },
    pageSize: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      refsDilogBox: '',
      showPrintShoesDialog: false,
      isHidden: false,
      url: '',
      title: ''
    }
  },
  computed: {
  },
  mounted() {
    this.$on('showDialog', () => {
      this.printClick()
    })
  },
  methods: {
    isSpecialStyle(size) {
      return size.indexOf('M') !== -1 || size.indexOf('-') !== -1
    },
    print() {
      this.$nextTick(() => {
        this.showPrintShoesDialog = true
        setTimeout(() => {
          this.pageSize === '10*5' ? this.shoes.forEach((item, index) => {
            this.$print(this.printShoesRef[index])
          }) : this.$notify({
            message: '操作成功，请前往文件导出界面下载',
            type: 'success'
          })
        })
      })
    },
    async printClick() {
      if (this.shoes.length > 0) {
        this.showPrintShoesDialog = true
        this.$nextTick(() => {
          this.print()
        })
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('title.onePrintDetail')
        })
        return
      }
    },
    closeDialog() {
      document.title = 'SCM Admin'
    },
    show() {
      this.$nextTick(() => {
        this.printShoesRef = this.$refs.printShoesRef
      })
    }

  }
}
</script>
<style type="text/css" media="print,screen">
div {
  -webkit-print-color-adjust: exact;
}
</style>
<style>
  body {
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  }
  .shoes-box {
    width: 500px;
    height: 210px;
  }

  .left-box {
    float: left;
    width: 45%;
    height: 180px;
  }

  .right-box {
    float: right;
    width: 55%;
    height: 180px;
  }

  .sc li {
    display: inline;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    width: 50%;
  }

  .code {
    width: 500px;
    text-align: left;
    font-size: 20px;
    line-height: 18px;
    font-weight: 500;
    margin: 10px 0 0 15px;
  }

  .imgQr {
    display: inline-block;
    background-color: #eee;
    width: 135px;
    height: 140px;
  }

  .style-class {
    width: 100% !important;
    text-align: center !important;
    font-size: 35px;
    line-height: 1;
    font-weight: 1000;
    font-family: "Microsoft YaHei";
  }

  .size {
    font-size: 32px;
    line-height: 32px !important;
    font-weight: 800;
    color: #fff;
    margin: 8px 0 5px 0 !important;
    padding: 0px 5px;
    font-family: "Microsoft YaHei";
  }

  .special-size {
    font-size: 45px;
    line-height: 45px !important;
    background-color: #fff;
    color: '#fff';
    text-align: left;
  }

  .show-margin {
    margin-top: 18px !important;
  }

  .colors {
    font-size: 30px;
    line-height: 1;
    font-weight: 800;
    text-decoration: underline;
    font-family: "Microsoft YaHei";
    width: 500px !important;
    text-align: center !important;
    margin: 0px 0 0 0 !important;
  }

  .short-color {
    font-size: 30px;
    line-height: 1;
    font-weight: 800;
    text-decoration: underline;
    font-family: "Microsoft YaHei";
    margin: 0px 0 0 150px !important;
  }

  .div-specail-style {
    height: 25px;
    line-height: 25px;
  }
  .div-style {
  padding: 5px 0 4px  0;
  font-size: 16px;
}
.a4 {
  height: 25%!important;
}
  .show-border {
    border-top: 3px solid #000;
  }
</style>
