<template>
  <div>
    <el-dialog title="修改包装信息" :visible.sync="visible" width="800px" :close-on-click-modal="false">
      <el-table ref="singleTable" :loading="loading" :data="receiveLogs" highlight-current-row style="width: 100%">
        <el-table-column type="index" width="80" label="序号" />
        <el-table-column prop="style" label="Style" />
        <el-table-column prop="packagingCodeId" label="包装代码" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.packagingCodeId" :placeholder="$t('page.selectPlaceholder')" filterable @change="change">
              <el-option
                v-for="item in packagingCodeOptions"
                :key="item.id"
                :label="item.packagingCode"
                :value="item.id"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="shoeFoldFlag" label="鞋是否折叠" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.shoeFoldFlag" :placeholder="$t('page.selectPlaceholder')" filterable @change="change">
              <el-option label="Y" value="Y" />
              <el-option label="N" value="N" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="insideLabelDict" label="内里标" align="center">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.insideLabelDict"
              class="style_w100"
              clearable
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              @change="change"
            >
              <el-option
                v-for="item in _getAllCommodityDict('SCM_INSIDE_LABEL')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" :loading="submitLoading" :disabled="submitFlag" @click="handleSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { vieworderId, modifyPackageCode } from '@/api/scm-api'
import { getPackagingCode } from '@/api/package'
import { deepClone } from '@/utils'

export default {
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      submitFlag: true,
      loading: false,
      form: {},
      packagingCodeOptions: [],
      receiveLogs: [],
      submitLoading: false,
      defaultLogs: []
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    params() {
      const { style, packagingCodeId, shoeFoldFlag, insideLabelDict } = this.receiveLogs[0]
      return Object.assign({}, {
        // 未修改包装信息
        purchaseOrderCode: this.row.purchaseOrderCode,
        style, shoeFoldFlag,
        packagingCode: this.packagingCodeOptions.find(i => i.id === packagingCodeId).packagingCode || null,
        packagingCodeId: packagingCodeId || 0,
        insideLabelDict: insideLabelDict || '', packageCodeVersion: null, packagingEffectiveTime: null,
        detailList: this.receiveLogs.map(item => {
          const packagingCode = this.packagingCodeOptions.find(i => i.id === item.packagingCodeId).packagingCode || null
          if (!item.insideLabelDict) {
            return { style: item.style, shoeFoldFlag: item.shoeFoldFlag, packagingCode, packagingCodeId: item.packagingCodeId || 0, insideLabelDict: '', packageCodeVersion: null, packagingEffectiveTime: null }
          } else {
            return { style: item.style, shoeFoldFlag: item.shoeFoldFlag, packagingCode, packagingCodeId: item.packagingCodeId, insideLabelDict: item.insideLabelDict }
          }
        })
      })
    }
  },
  watch: {
    'value'(val) {
      if (val) {
        this._packinginfoList()
        this._handleDetail()
        this.submitFlag = true
      }
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    change() {
      // 有修改才允许提交
      let arr = []
      this.receiveLogs.map(item => {
        arr = this.defaultLogs.reduce((acc, cur) => {
          this.sameItem(item, cur) ? acc.push(item) : acc
          return acc
        }, [])
      })
      arr.length !== this.receiveLogs.length ? this.submitFlag = false : this.submitFlag = true
    },
    async _packinginfoList() {
      const { datas } = await getPackagingCode()
      this.packagingCodeOptions = datas
    },
    async handleSubmit() {
      // 包装＋折叠唯一
      if (this.receiveLogs.some(item => !item.packagingCodeId || !item.shoeFoldFlag)) {
        this.$message({
          message: '【包装代码，鞋是否折叠】为必填项，请核对',
          type: 'warning'
        })
        return
      }
      this.$confirm('是否确认修改?', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      }).then(async() => {
        try {
          this.submitLoading = true

          const { code, msg } = await modifyPackageCode(this.params)
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.$emit('input', false)
          }
        } finally {
          this.submitLoading = false
        }
      })
    },
    findItem(acc, e) {
      return acc.find(item => item.style === e.style)
    },
    sameItem(item, e) {
      return item.id === e.id && item.packagingCodeId === e.packagingCodeId && item.shoeFoldFlag === e.shoeFoldFlag && item.insideLabelDict === e.insideLabelDict
    },

    async _handleDetail() {
      try {
        this.loading = true
        const { datas: { detailList, packagingCodeId, insideLabelDict }} = await vieworderId(this.row.id)
        this.receiveLogs = detailList.reduce((acc, cur) => {
          !this.findItem(acc, cur) ? acc.push(cur) : acc
          return acc
        }, []).map(item => { return { ...item, packagingCodeId: packagingCodeId || null, insideLabelDict: insideLabelDict || null } })
        this.defaultLogs = deepClone(this.receiveLogs)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
