<template>
  <div>
    <el-dialog :title="title" :visible.sync="visible" width="900px" :close-on-click-modal="false">
      <Table
        :table-data="detailTable"
        :max-height="'450px'"
        :columns="columns"
      >
        <el-table-column slot="substandardI18" label="LT不达标原因" align="center" width="160">
          <template slot-scope="scope">
            {{ scope.row.substandardI18&&scope.row.substandardDict?scope.row.substandardI18:'' }}
          </template>
        </el-table-column>
      </Table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import Table from '@/components/Table'

export default {
  components: { Table },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    },
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    },
    api: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      detailTable: []
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  watch: {
    'value'(val) {
      if (val) {
        this._VendorDeliveryFeedback(this.row.purchaseOrderCode)
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    async _VendorDeliveryFeedback(code) {
      const { datas } = await this.api(code)
      this.detailTable = datas.map(item => { return { ...item, deliveryDelayI18: item.deliveryDelay ? item.deliveryDelayI18 : '' } })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
