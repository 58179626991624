<template>
  <div>
    <el-dialog
      title="实测包装信息导入"
      :visible.sync="visible"
      width="80%"
      class="upload-dialog"

      :close-on-click-modal="false"
      @close="closed"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="上传文件" />
        <el-step title="数据预览" />
        <el-step title="确认采购订单" />
        <el-step title="导入数据" />
      </el-steps>
      <main class="upload-order-content">
        <section v-show="active === 0" class="import-orders">
          <div class="download-template">
            <h3 class="my-2">填写导入数据信息</h3>
            <small>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</small>
            <el-button
              class="mt-2"
              type="text"
              :loading="downloadLoding"
              @click="handleTemplate"
            >下载模板</el-button>
          </div>
          <el-divider />
          <div>
            <h3 class="my-2">上传填好的信息表</h3>
            <small>文件后缀名必须为xls
              或xlsx（即Excel格式），文件大小不得大于10M，最多支持导入3000条数据</small>
            <ImportFile
              ref="uploadRef"
              :limit="1"
              class="mt-8px"
              :auto-upload="false"
              :upload-file="uploadPlanFile"
              accept=".xlsx, .xls"
              :types="['xls', 'xlsx']"
              :show-file-list="true"
              button-type="text"
              import-name="上传文件"
              @getFile="({file:val})=>file=val"
              @removeFile="handleFileRemove"
            />
          </div>
          <el-divider />

          <div class="download-template">
            <h3 class="my-2">特别提示</h3>
            <small>导入过程中如发现个别数据校验不通过，则全量回滚修正后再重新操作导入</small>
          </div>
        </section>
        <section v-show="active === 1" class="import-orders">
          <Table
            class="mt-16px"
            :columns="packageViewColumns"
            max-height="600px"
            :table-data="tableInfo.packagingInformationImportVOList"
          />
          <Paging :pager="pager" end @pagination="pagerUpdate" />
        </section>
        <section v-show="active === 2">
          <!-- 文案提示 -->
          <ElRow class="mb-4">
            <ElCol :span="20">下方列表中为此次实测包装信息导入将要更新包装信息的采购订单。</ElCol>
            <ElCol :span="20">默认全部勾选，取消勾选，则不更新其包装信息</ElCol>
            <ElCol :span="20">请视实际情况确认下列采购订单是否均须更新为当前导入的实测包装信息</ElCol>
          </ElRow>
          <vxe-table
            id="myTable"
            ref="multipleTable"
            max-height="500px"
            align="center"
            highlight-hover-row
            :data="tableInfo.orderVOList"
            auto-resize
            :show-overflow="true"
            resizable
            @checkbox-all="({records})=>multipleTable=records"
            @checkbox-change="({records})=>multipleTable=records"
          >
            <vxe-table-column type="checkbox" width="50" />
            <vxe-table-column width="90" type="seq" title="序号" />
            <vxe-table-column field="purchaseOrderCode" title="采购订单号" />
            <vxe-table-column field="style" title="款号" />
            <vxe-table-column field="vendorName" title="供应商" />
            <vxe-table-column field="produceProgressDate" title="下单日期" />
          </vxe-table>
        </section>
        <section v-show="active === 3" class="data-review">
          <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage" />
          <p
            v-if="percentage"
            class="mt-16px"
            style="text-align: center"
          >正在导入数据，请勿关闭或刷新页面</p>
        </section>
        <section v-show="active === 4" style="text-align: center">
          <el-result icon="success" title="批量导入完成" :sub-title="`您已成功导入${tableInfo.defaultList.length}条数据`" />
        </section>
      </main>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-show="active === 0" @click="closed">取消</el-button>
          <el-button v-show="[1,2].includes(active)||importError" @click="handleToReview('previous')">上一步</el-button>
          <el-button
            v-show="active === 0||active === 1"
            type="primary"
            :loading="loading"
            @click="handleToReview('next')"
          >下一步</el-button>
          <el-button
            v-show="active === 2"
            type="primary"
            :loading="loading"
            @click="handleToReview('submit')"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div></template>

<script>
import ImportFile from '@/components/ImportFile'
import Table from '@/components/Table'
import { packageViewColumns } from '../columns.js'
import { packagingImportView, packagingImport } from '@/api/package'
import Paging from '@/components/Pagination'

export default {
  components: { ImportFile, Table, Paging },
  props: {
    value: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      pager: { size: 20, current: 1, total: 0 },
      multipleTable: [],
      packageViewColumns,
      file: '',
      fileList: [],
      active: 0,
      downloadLoding: false,
      loading: false,
      percentage: 0,
      importError: false,
      tableInfo: {
        orderVOList: [],
        packagingInformationImportVOList: [],
        defaultList: []
      }
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(val) {
      if (val) {
        this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      }
    },
    'active'(val) {
      if (val === 2) {
        // 默认全选
        this.$refs.multipleTable.setAllCheckboxRow(true)
        this.multipleTable = this.tableInfo.orderVOList
      }
      if (val !== 3) {
        this.importError = false
      }
    }
  },
  methods: {
    async handleTemplate() {
      try {
        this.downloadLoding = true
        const url = 'https://aliyun-oa-query-results-1509030252432744-oss-cn-hongkong.oss-cn-hongkong.aliyuncs.com/template/SCM%E5%AE%9E%E6%B5%8B%E5%8C%85%E8%A3%85%E4%BF%A1%E6%81%AF%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
        window.open(url, '_blank')
      } finally {
        this.downloadLoding = false
      }
    },
    pagerUpdate(val) {
      Object.assign(this.pager, val)
      const { current, size } = this.pager
      this.tableInfo.packagingInformationImportVOList = this.tableInfo.defaultList.slice((current - 1) * size, current * size)
    },
    handleFileRemove() {
      this.$refs.uploadRef.$refs.uploadForm.clearFiles()
    },
    async uploadPlanFile() {
      try {
        this.loading = true
        const form = new FormData()
        form.append('file', this.file)
        const { datas: { packagingInformationImportVOList = [], orderVOList = [] }} = await packagingImportView(form)
        this.tableInfo.orderVOList = orderVOList
        this.tableInfo.defaultList = packagingInformationImportVOList
        const { current, size } = this.pager
        this.pager.total = packagingInformationImportVOList.length
        this.tableInfo.packagingInformationImportVOList = packagingInformationImportVOList.slice((current - 1) * size, current * size)
        this.file = ''
        this.fileList = []
        this.active++
      } finally {
        this.loading = false
        this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      }
    },
    handleToReview(type) {
      this.currentStatus(type)
    },
    closed() {
      this.file = ''
      this.active = 0
      this.$emit('input', false)
    },
    currentStatus(type) {
      if (type === 'next') {
        if (this.active === 0) {
          if (!this.file) return this.$message.warning('请上传文件')
          this.$refs.uploadRef.$refs.uploadForm.submit()
        }
        // 有错误消息的不可以下一步
        if (this.active === 1) {
          if (this.tableInfo.defaultList.some(item => item.errorMessage)) return this.$message.warning('存在错误提示，请核对')
          this.active++
        }
      }
      if (type === 'previous') {
        this.active--
      }
      if (type === 'submit') {
        if (this.tableInfo.defaultList.some((item) => item.errorMessage)) { return this.$message.warning('当前预览数据存在错误，无法提交') }
        // 必须选中一个文件
        if (!this.multipleTable.length) return this.$message.warning('必须选中至少一个PO单')
        this.handleImport()
      }
      if (type === 'success') {
        this.active = 4
        this.$emit('update')
      }
    },
    async handleImport() {
      try {
        this.loading = true
        this.active = 3
        const { defaultList } = this.tableInfo
        const { code } = await packagingImport({ orderVOList: this.multipleTable, packagingInformationImportVOList: defaultList }, (progressEvent) => {
          const complete = ((progressEvent.loaded / progressEvent.total) * 100 | 0)
          this.percentage = complete
        })
        if (code === 0) {
          // 提示成功页面
          this.percentage = 100
          this.currentStatus('success')
        }
      } catch (e) {
        this.percentage = 0
        this.importError = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.upload-dialog /deep/ {
    .el-step__title.is-success, .el-step__head.is-success , .el-step__description.is-success,
    .el-step__title.is-process, .el-step__head.is-process , .el-step__description.is-process{
    color: #1890ff;
    border-color: #1890ff;
  }
}
.upload-order-content {
  width: 90%;
  padding: 50px 0px;
  margin: 0 auto;

  .download-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .upload-error--item {
    display: flex;
    align-items: center;
  }

  .upload-order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .el-upload--text {
      order: 2;
    }

    .el-upload__tip {
      order: 1;
    }

    .el-upload-list.el-upload-list--text {
      order: 3;
    }
  }
}
</style>
