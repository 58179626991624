<template>
  <div>
    <el-dialog title="" :visible.sync="showPrintShoesDialog" fullscreen @open="show()" @close="closeDialog">
      <div v-for="(item,index) in shoes" :key="index">
        <div ref="printShoesRef" :class="flag === 1 ?'ml-3':'ml-0'">
          <el-row>
            <el-col v-for="(row, idx) in item" :key="idx" :span="12" style="height: 240px;">
              <div v-if="row.style.length>9" style="display: flex;width:360px;" class="mb-1">
                <div class="style-in">{{ row.style }}</div>
              </div>
              <div v-else style="display: flex;width:360px;" class="mb-1">
                <div style="font-size: 24px;font-weight: 1000;margin-left:88px;font-family: 'Microsoft YaHei';">
                  {{ row.style }}</div>
              </div>

              <div style="display: flex">
                <div>
                  <div style="width: 85px; height: 100px;">
                    <img style="width: 100%; height: 100px;" :src="row.imgUrl">
                  </div>
                </div>
                <div style="width: 150px;height:115px!important;">
                  <div style="display: block; text-align: center; margin: 0 auto">
                    <barcode
                      element-tag="img"
                      :value="row.upc||row.fnsku"
                      format="CODE128"
                      font="cursive"
                      font-size="18"
                      height="120"
                      text-margin="4"
                      margin-top="-4"
                      margin-bottom="0"
                    />
                  </div>
                </div>
                <div style="font-size:16px;line-height:10px;font-weight:500;" class="ml-1">
                  <div v-if="row.shoeTreeStandard==2">
                    <div
                      class=" size-in size-special-eur mb-1 mt-0"
                      :style="{backgroundColor:`${row.sizeColor}`,color:row.sizeColor?'#fff':'#000',textAlign:'left'}"
                    >
                      EUR:{{ row.sizeEUR }}
                    </div>
                    <div class="show-in-border div-in-special-style">US: {{ row.sizeUS }}</div>
                  </div>
                  <div v-else>
                    <div
                      v-if="isSpecialStyle(row.sizeUS)"
                      class="size-in mt-0 mb-1"
                      :style="{backgroundColor:`${row.sizeColor}`,color:row.sizeColor?'#fff':'#000',textAlign:'left'}"
                    >
                      <div>US:</div>
                      <div style="text-align:left;">{{ row.sizeUS }}</div>
                    </div>
                    <div
                      v-else
                      class="size-in mt-0 mb-1 size-special-eur"
                      :style="{backgroundColor:`${row.sizeColor}`,color:row.sizeColor?'#fff':'#000',textAlign:'left'}"
                    >
                      US:{{ row.sizeUS }}
                    </div>
                    <div :class="isSpecialStyle(row.sizeUS)?'div-in-style':'div-in-special-style'" class="show-in-border">EUR:
                      {{ row.sizeEUR }}</div>
                  </div>
                  <div class="div-in-special-style">UK: {{ row.sizeUK }}</div>
                  <div class="div-in-special-style">JPN: {{ row.sizeJPN }}</div>

                </div>
              </div>
              <div :class="row.color.length>10?'color-in':'short-color-in'">{{ row.color }}</div>
              <div class="code-v">
                <div>{{ row.boxCode }}</div>
                <div v-if="row.shoeTreeStandard==2">MADE IN P.R.C</div>
                <div v-else>MADE IN CHINA</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
export default {
  name: 'PrintShoesDialog',
  components: {
    'barcode': VueBarcode
  },
  props: {
    shoes: {
      type: Array,
      default() {
        return {

        }
      }
    },
    flag: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      refsDilogBox: '',
      showPrintShoesDialog: false,
      isHidden: false,
      url: '',
      title: ''
    }
  },
  computed: {

  },
  mounted() {
    this.$on('showDialog', () => {
      // this.printClick()
      this.showPrintShoesDialog = true

      setTimeout(() => {
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
      }, 0)
    })
  },
  methods: {
    isSpecialStyle(size) {
      return size.indexOf('M') !== -1 || size.indexOf('-') !== -1
    },
    closeDialog() {
      document.title = 'SCM Admin'
    },
    show() {
      this.$nextTick(() => {
        this.printShoesRef = this.$refs.printShoesRef
      })
    }

  }
}
</script>
<style>
 html,body{
  color:"#000";
}
  .mt-0 {
  margin-top: 0 !important;
}
  .mb-1 {
    margin-bottom: 4px;
  }
  .sc li {
    display: inline;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    width: 50%;
  }

  .code-v {
    width: 340px;
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    font-weight: 500;
    margin: 8px 0 0 0;
    display: flex;
    justify-content: space-between;
  }

  .style-in {
    width: 100% !important;
    text-align: center !important;
    font-size: 24px;
    font-weight: 1000;
    font-family: "Microsoft YaHei";
  }

  .size-special-eur {
    font-size: 30px!important;
    line-height: 30px!important;
    height: 30px!important;
  }

  .size-in {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 800;
    color: "#fff";
    line-height: 18px;
    text-align: center;
    padding: 0px 6px;
    font-family: "Microsoft YaHei";
  }

  .eur {
    font-size: 45px !important;
  }

  .color-in {
    width: 320px !important;
    line-height: 20px;
    font-size: 20px;
    font-weight: 800;
    text-decoration: underline;
    text-align: center;
    font-family: "Microsoft YaHei";
  }

  .short-color-in {
    line-height: 20px;
    font-size: 20px;
    font-weight: 800;
    text-decoration: underline;
    margin-left: 90px;
    font-family: "Microsoft YaHei";
  }

  .vue-barcode-element {
    width: 150px;
    height: 110px;
  }

  .uk {
    margin: 15px 0;
  }

  .sizeeur {
    margin-top: 2px !important;
  }

  .div-in-style {
    padding: 4px;
  }

  .div-in-special-style {
    height: 18px;
    line-height: 18px;
    padding-left: 4px;
  }

  .show-in-border {
    border-top: 3px solid #000;
  }
</style>
