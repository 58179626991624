<template>
  <!-- 采购订单 -->
  <div
    id="app"
    class="app-container"
    style="display: none"
    :style="{ display: 'block' }"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
      style="display: flex"
      class="order"
    >
      <div class="el-lt" style="width: 90%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="采购单号" :class="$i18n.locale">
              <el-input
                v-model="form.purchaseOrderCode"
                placeholder="多个以换行符分隔"
                type="textarea"
                autosize
                resize="none"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="采购计划单号"
              :class="$i18n.locale"
              label-width="100px"
            >
              <el-input
                v-model="form.purchasePlanCode"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="状态" :class="$i18n.locale">
              <Select
                v-model="form.orderStatus"
                :select-options="_getAllCommodityDict('PURCHASE_ORDER_STATUS')"
                clearable
                multiple
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorId"
                multiple
                filterable
                clearable
                style="width:100%;"
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.style"
                oninput="(/\s+/g, '')"
                multiple
                value-key="id"
                clearable
                filterable
                style="width:100%;"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="季节" :class="$i18n.locale">
              <Select
                v-model="form.seasonIdStr"
                :select-options="_getAllCommodityDict('SEASON')"
                clearable
                multiple
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="BU" :class="$i18n.locale">
              <el-select
                v-model="form.operatorGroup"
                filterable
                clearable
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
                @change="changeBU"
              >
                <el-option
                  v-for="(item, index) in groupoptions"
                  :key="index"
                  :label="item.groupName"
                  :value="item.groupName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Operator" :class="$i18n.locale">
              <el-select v-model="form.operatorUser" filterable clearable :placeholder="$t('page.selectPlaceholder')" style="width:100%;">
                <el-option
                  v-for="(item, index) in useroptions"
                  :key="index"
                  :label="item.userName"
                  :value="item.userName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="定位" :class="$i18n.locale">
              <Select
                v-model="form.positionIdStr"
                style="width:100%;"
                api-key="position"
                clearable
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备货渠道">
              <el-select v-model="form.stockChannelIdStr" style="width:100%;" multiple clearable filterable collapse-tags>
                <el-option
                  v-for="item in StockChannelOptions"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="style品类" label-width="75px">
              <el-cascader
                v-model="form.categoryId"
                style="width:100%;"
                clearable
                collapse-tags
                filterable
                separator=">"
                :options="basecategorydatas"
                class="style_w100"
                :props="{
                  value: 'id',
                  label: 'categoryName',
                  children: 'sonCategory',
                  multiple: true,
                }"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="质检状态" :class="$i18n.locale">
              <Select
                v-model="form.qualityStatus"
                :select-options="_getAllCommodityDict('QUALITY_STATUS')"
                clearable
                multiple
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购员" :class="$i18n.locale">
              <el-input
                v-model="form.purchasePersonnel"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="关联合同">
              <el-select
                v-model="form.connectContractFlag"
                clearable
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option label="已生成" :value="true" />
                <el-option label="未生成" :value="false" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否已结">
              <el-select
                v-model="form.settledFlag"
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
                clearable
              >
                <el-option label="是" :value="true" />
                <el-option label="否" :value="false" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送货方式">
              <Select
                v-model="form.deliveryMethod"
                style="width:100%;"
                :select-options="_getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
                :configuration="{ key: 'val', label: 'label', value: 'val' }"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货地点">
              <Select
                v-model="form.receiptPlaceCode"
                style="width:100%;"
                api-key="getWarehouse"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下单日期">
              <el-date-picker
                v-model="form.value3"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="计划评审到货日期"
              :class="$i18n.locale"
              label-width="125px"
            >
              <el-date-picker
                v-model="form.value2"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单标签">
              <el-select
                v-model="form.tagDictList"
                clearable
                multiple
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_PURCHASE_ORDER_TAG')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="内里标签" :class="$i18n.locale">
              <Select
                v-model="form.insideLabelDictList"
                :select-options="_getAllCommodityDict('SCM_INSIDE_LABEL')"
                :configuration="{ key: 'val', label: 'label', value: 'val' }"
                clearable
                multiple
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style Partner Code">
              <el-input
                v-model="form.stylePartnerCodeList"
                placeholder="多个以换行符分隔"
                type="textarea"
                style="width:100%;"
                autosize
                resize="none"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style Name">
              <el-input
                v-model="form.productNameList"
                placeholder="多个以换行符分隔"
                type="textarea"
                style="width:100%;"
                autosize
                resize="none"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销售季节">
              <Select
                v-model="form.saleSeasonDictList"
                :select-options="_getAllCommodityDict('SCM_SALE_SEASON')"
                clearable
                multiple
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row
      v-if="getNumLog > 0"
      :span="24"
      class="mb-3"
      justify="end"
      style="margin-right: -30px"
    >
      <el-col :span="24">
        <div class="notification-container" @click="toDoVisible = true">
          <div class="updateText">您有待办事项，请及时处理</div>
          <div class="updateCount">{{ `${getNumLog}` }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row :span="24" class="mb-3">
      <div class="header_btn">
        <el-button
          type="primary"
          @click="Ordervendor()"
        >分配供应商生产</el-button>
        <el-button
          type="primary"
          @click="sendImportDialog = true"
        >发货单导入</el-button>
        <el-button
          type="primary"
          @click="uploadDeliveryVisible = true"
        >交期导入</el-button>
        <el-badge :value="feedbackCount">
          <el-button
            type="primary"
            @click="deliveryDialog = true"
          >供应商交期确认</el-button>
        </el-badge>
        <ImportFile
          ref="uploadPlanForm"
          v-model="uploadTablePlanVisible"
          :upload-file="uploadPlanFile"
          :import-details-columns="planDetailsColumns"
          :auto-upload="true"
          style="display: inline-block!important;"
          :details-data="importPlanData"
          import-name="计划评审时间导入"
          :import-submit-loading="importSubmitLoading"
          @getFile="({ file: val }) => (file = val)"
          @getSelectionChange="val => (dialogselectlist = val)"
          @submitDetailDatas="submitPlanDatas"
        />
        <ImportFile
          ref="uploadShoesForm"
          v-model="uploadTableShoesVisible"
          :upload-file="uploadShoesBatchFile"
          :import-details-columns="shoesBatchDetailsColumns"
          :auto-upload="true"
          style="display: inline-block!important;"
          :details-data="importShoesData"
          import-name="鞋印批次号导入"
          :import-submit-loading="importSubmitLoading"
          @getFile="({ file: val }) => (file = val)"
          @getSelectionChange="val => (dialogselectlist = val)"
          @submitDetailDatas="submitShoesBatchDatas"
        />
        <el-button
          type="primary"
          @click="listDetailByPrint"
        >打印PDF鞋标</el-button>
        <!-- // <el-button type="primary" @click="_batchPrintShoesCode">批量导出PDF鞋标</el-button> -->
        <el-button
          type="primary"
          @click="batchCompletion"
        >批量缺量收货</el-button>
        <el-button
          type="primary"
          @click="handleChangeDelivery"
        >变更送货信息</el-button>
        <el-button
          v-permission="'package'"
          type="primary"
          @click="packageDialogVisible = true"
        >实测包装信息导入</el-button>
        <el-button
          v-permission="'ordertag'"
          type="primary"
          @click="uploadDialogVisible = true"
        >订单标签导入</el-button>
      </div>
    </el-row>
    <el-row :span="24" class="mb-3">
      <el-col :span="12">
        <el-button :loading="exportLoading" @click="handleExport('all')">导出</el-button>

        <el-button :loading="exportLoading" @click="handleExport('detail')">导出明细</el-button>
        <el-button @click="handleContract">生成采购合同</el-button>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-switch
          v-model="channel"
          active-text="展示渠道下单量"
          @change="showChannel"
        />
      </el-col>
    </el-row>
    <el-row
      class="select-info my-2 px-2"
      :class="quantityStyle"
      type="flex"
      justify="space-between"
    >
      <el-col :span="12">
        <i class="el-icon-info i" />
        <span
          class="ml-2 i"
        >{{ $t("page.alreadySel") }} {{ quantity }} 项</span>
        <el-button
          :disabled="quantity === 0"
          type="text"
          class="ml-2"
          @click="clearSelection"
        >清空</el-button>
      </el-col>
    </el-row>
    <div class="vxe-warp">
      <vxe-table
        id="myTable"
        ref="multipleTable"
        v-loading="TableLoading"
        :height="tableHeight"
        align="center"
        highlight-hover-row
        :data="tableDatas"
        :footer-method="footerMethod"
        show-footer
        auto-resize
        :show-overflow="false"
        resizable
        class="table-fixed"
        @checkbox-all="selectChangeEvent"
        @checkbox-change="selectChangeEvent"
      >
        <vxe-table-column type="checkbox" />
        <vxe-table-column width="50" type="seq" title="序号" />
        <vxe-table-column
          width="150"
          field="purchaseOrderCode"
          title="采购订单号"
          sortable
        >
          <template v-slot="{ row }">
            <router-link
              :to="{
                path: '/purchasbusiness/purchaseorder/purchdetail',
                query: { id: row.id, disabled: true }
              }"
              style="color: #1890ff"
            >{{ row.purchaseOrderCode }}</router-link>
          </template>
        </vxe-table-column>
        <vxe-table-column
          width="140"
          field="contractCode"
          title="采购合同号"
          sortable
        >
          <template v-slot="{ row }">
            <router-link
              :to="{
                path: '/purchasbusiness/contract/ordercontract'
              }"
              style="color: #1890ff"
            >{{ row.contractCode }}</router-link>
          </template>
        </vxe-table-column>
        <vxe-table-column width="90" field="firstFlag" title="是否首单">
          <template v-slot="{ row }">
            <span v-if="row.firstFlag == true">是</span>
            <span v-if="row.firstFlag == false">否</span>
          </template>
        </vxe-table-column>
        <vxe-table-column width="160" field="purchasePlanCode" title="采购计划单号" sortable />
        <vxe-table-column width="160" field="stockChannelName" title="备货渠道" sortable />
        <vxe-table-column width="100" field="orderStatusI18" title="订单状态" sortable />
        <vxe-table-column width="120" field="purchaseOrderTagList" title="订单标签">
          <template v-slot="{ row }">
            <el-popover placement="top-start" trigger="hover">
              <span v-for="(item,idx) in row.purchaseOrderTagList" :key="idx">
                <el-tag v-if="item" round color="#6cc9ec">
                  <span style="color: #fff">{{ item.tagI18 }}</span>
                </el-tag>
              </span>
              <span
                v-for="(item,idx) in row.purchaseOrderTagList"
                :key="idx"
                slot="reference"
              >
                <el-tag v-if="item" round color="#6cc9ec">
                  <span style="color: #fff">{{ item.tagI18 }}</span>
                </el-tag>
              </span>
            </el-popover>
          </template>
        </vxe-table-column>
        <vxe-table-column
          width="100"
          field="qualityStatusI18"
          title="质检状态"
          sortable
        />
        <vxe-table-column
          width="95"
          field="vendorName"
          title="供应商"
          sortable
        />
        <vxe-table-column
          width="95"
          field="deliveryMethodI18"
          title="送货方式"
          sortable
        />
        <vxe-table-column
          width="95"
          field="receiptPlaceName"
          title="收货地点"
          sortable
        />
        <vxe-table-column
          width="130"
          field="prepareOrderFlag"
          title="是否备料订单"
          sortable
        >
          <template v-slot="{ row }">
            <span v-if="row.prepareOrderFlag">是</span>
            <span v-if="!row.prepareOrderFlag">否</span>
          </template>
        </vxe-table-column>
        <vxe-table-column width="120" field="ltDay" title="可执行LT" sortable />
        <vxe-table-column width="90" field="cargoOwnerName" title="货主" sortable />
        <vxe-table-column width="120" field="predictDeliveryDate" title="期望交货日期" sortable />
        <vxe-table-column field="planDeliveryDate" title="计划评审到货日期" min-width="150" sortable />
        <vxe-table-column v-if="isPurchaseDepartment" field="supplierDeliveryFeedback" title="供应商反馈交货日期" min-width="150" sortable />
        <vxe-table-column width="150" field="vendorDeliveryFeedback" title="采购反馈到货日期" sortable>
          <template v-slot="{ row }">
            <span
              style="cursor:pointer;"
              :class="
                row.isRed === 1 || row.dflCount === '1'
                  ? 'specialColor'
                  : 'specialColorDefault'
              "
              @click="ClickEvent('delivery', row)"
            >{{ row.vendorDeliveryFeedback }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column width="120" title="未准交原因">
          <template v-slot="{ row }">
            <span v-if="row.deliveryDelayI18 !== 'null'">{{
              row.deliveryDelayI18
            }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column
          width="120"
          field="vendorDeliveryFeedbackRemark"
          title="交期反馈备注"
        />
        <vxe-table-column
          width="150"
          field="substandardI18"
          title="LT不达标原因"
        >
          <template v-slot="{ row }">
            <span
              v-if="row.substandardDict"
              style="cursor:pointer;color:#1890ff;"
              @click="ClickEvent('lt', row)"
            >{{ row.substandardI18 }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column
          width="100"
          field="purchasePersonnel"
          title="采购员"
          sortable
        />
        <vxe-table-column
          width="120"
          field="groupStyle"
          title="Style"
          sortable
        />
        <vxe-table-column width="100" field="stylePartnerCode" title="Style Partner Code" />
        <vxe-table-column width="100" field="productName" title="Style Name" />
        <vxe-table-column width="100" field="styleCategory" title="style品类" show-overflow />
        <vxe-table-column width="100" field="insideLabelDict" title="内里标签" />
        <vxe-table-column width="100" field="seasonI18" title="季节" />
        <vxe-table-column width="100" field="position" title="定位" />
        <vxe-table-column width="100" field="saleSeasonName" title="销售季节" />
        <vxe-table-column
          field="totalNumber"
          title="采购总数量"
          width="130"
          align="center"
          sortable
        />
        <vxe-table-column
          width="100"
          field="unclearedPairs"
          title="未结数量"
          sortable
        />
        <vxe-table-column
          field="collectNumber"
          title="收货数量"
          width="130"
          align="center"
          sortable
        />
        <vxe-table-column
          field="returnCollectNumber"
          title="返修收货"
          width="130"
          align="center"
          sortable
        />
        <vxe-table-column
          field="shortageReceiptQuantity"
          title="剩余未收货数量"
          width="140"
          align="center"
          sortable
        />
        <vxe-table-column
          field="returnNumber"
          title="退货数量"
          width="110"
          align="center"
          sortable
        />
        <template v-for="(item, index) in PlatFormAndSiteData.platformSiteList">
          <vxe-table-column
            v-if="showClass"
            :key="index"
            width="120"
            :field="item"
            :title="item"
            sortable
          />
        </template>
        <!-- <vxe-table-column field="currencyTypeI18" title="币种" width="90" align="center" sortable /> -->
        <vxe-table-column
          field="currencyType"
          title="币种"
          width="90"
          align="center"
          sortable
        >
          <template v-slot="{ row }">
            {{ getAuditTypeLabel("CURRENCY_TYPE", row.currencyType) }}
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="totalPrice"
          title="订单金额"
          width="110"
          align="center"
          sortable
        />
        <vxe-table-column
          field="changeTotalPrice"
          title="调价后总金额"
          align="center"
          min-width="120"
        />
        <vxe-table-column
          field="operatorGroupUser"
          title="BU-Operator"
          width="130"
          align="center"
          sortable
        />
        <vxe-table-column
          field="createByName"
          title="创建人"
          width="110"
          align="center"
          sortable
        />
        <vxe-table-column
          field="createTime"
          title="创建日期"
          width="110"
          align="center"
          sortable
        />
        <vxe-table-column
          field="produceProgressDate"
          title="下单日期"
          width="110"
          align="center"
          sortable
        />
        <vxe-table-column width="120" field="remark" title="备注" />
        <vxe-table-column
          width="150"
          field="supplierDownTime"
          title="供应商下载明细时间"
        />
        <vxe-table-column
          fixed="right"
          title="操作"
          width="280px"
          align="center"
        >
          <template v-slot="{ row }">
            <el-button type="text" size="small" @click="vieworder(row)">
              <p>查看</p>
            </el-button>
            <el-button
              v-permission="'delete'"
              type="text"
              size="small"
              @click="deleteorder(row.id)"
            >
              <p v-if="row.orderStatus === 1">作废</p>
            </el-button>
            <el-button
              v-permission="'back'"
              type="text"
              size="small"
              @click="backOrder(row)"
            >
              <p v-if="row.orderStatus === 1 || row.orderStatus === 4">回滚</p>
            </el-button>
            <el-button type="text" size="small" @click="Vendororder(row)">
              <p v-if="row.orderStatus !== 7 && row.orderStatus !== 8">交期反馈</p>
            </el-button>
            <el-button v-if="row.orderStatus !== 7 && row.orderStatus !== 8" type="text" size="small" @click="exportContractdata(row.purchaseOrderCode, row.orderStatus)">
              导出订单
            </el-button>
            <el-button
              v-permission="'purchasecancel'"
              type="text"
              size="small"
              @click="cancelOrder(row.purchaseOrderCode, 2,row.orderStatus)"
            >
              <p v-if="row.orderStatus === 1 || row.orderStatus === 4">取消</p>
            </el-button>
            <el-button
              v-permission="'operatecancel'"
              type="text"
              size="small"
              @click="cancelOrder(row.purchaseOrderCode, 1,row.orderStatus)"
            >
              <p v-if="row.orderStatus === 1 || row.orderStatus === 4">取消</p>
            </el-button>
            <el-button type="text" size="small" @click="editPackage(row)">
              <p>修改包装信息</p>
            </el-button>
            <el-button
              v-if="row.orderStatus !== 7 && row.orderStatus !== 8"
              type="text"
              size="small"
              @click="_shortageCompletion(row)"
            >缺量收货</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>

      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <!-- 确认分配供应商生产 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="分配供应商生产"
      width="800px"
    >
      <el-row>
        <el-button type="text" @click="downVendorTemplate">模板下载</el-button>
        <ImportFile
          ref="uploadVendorForm"
          v-model="uploadTableVendorVisible"
          class="inline-block"
          :button-type="'text'"
          :upload-file="uploadVendorFile"
          :import-details-columns="VenndorConfirmColumns"
          :auto-upload="true"
          :show-confirm="true"
          style="display: inline-block!important;"
          :details-data="importVendorData"
          import-name="批量导入"
          :import-submit-loading="importSubmitLoading"
          @getFile="({ file: val }) => (file = val)"
          @getSelectionChange="val => (dialogselectlist = val)"
          @submitDetailDatas="submitVendorDatas"
        />
        <div class="inline-block">
          出货日期批量
          <el-input-number v-model="vendorDate" @change="editDay()" /> 天
        </div>
      </el-row>

      <vxe-table
        :data="VendortableDatas"
        max-height="500px"
        align="center"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
      >
        <vxe-column type="index" width="80" title="序号" />
        <vxe-column field="purchaseOrderCode" title="采购订单号" />
        <vxe-column field="styleName" title="Style" />
        <vxe-column field="vendorName" title="供应商" />
        <vxe-column field="predictDeliveryDate" title="预计交货日期" />
        <vxe-column
          field="estiDeliveryTime"
          title="需求交货日期"
          :edit-render="{}"
        >
          <template #edit="{row}">
            <vxe-input
              v-model="row.estiDeliveryTime"
              type="date"
              placeholder="请选择日期"
              transfer
            />
            <!-- :disabled-method="disabledDateMethod"
              <el-date-picker
              v-model="row.estiDeliveryTime"
              type="date"
              style="width:100%;"
              value-format="yyyy-MM-dd"
              :picker-options="estiDeliveryDateOptions"
              placeholder="选择日期"
            /> -->
          </template>
        </vxe-column>
      </vxe-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="allocationVendorLoading"
          @click="save"
        >确 定</el-button>
      </span>
    </el-dialog>

    <!-- 取消订单 -->
    <el-dialog
      title="提示"
      :visible.sync="cancelDialogFormVisible"
      width="30%"
      @close="clodeCancelDialog"
    >
      <el-form
        ref="ruleForm"
        :rules="cancelrules"
        label-width="100px"
        class="demo-ruleForm"
        :model="ruleForm"
      >
        <el-form-item label="取消原因" prop="cancelReason">
          <el-input v-model="ruleForm.cancelReason" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="cancelOrderSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 发货单导入 -->
    <SendImport v-model="sendImportDialog" @refresh="queryClick" />
    <!-- 收货记录 -->
    <ReceiveLog v-model="receiveLogDialog" :row="editRow" />
    <!-- 供应商交期确认 -->
    <DeliveryConfirmation
      v-model="deliveryDialog"
      :row="editRow"
      @refresh="queryClick"
    />
    <!-- 交期反馈 -->
    <DeliveryFeedback
      v-model="vendordialogFormVisible"
      :row="editRow"
      @refresh="queryClick"
    />
    <!-- 打印pdf鞋标 -->
    <DownloadShoesPdf v-model="orderDetailDialog" :codes="codes" />
    <!-- 缺量收货 -->
    <ShortageCompletionForm
      v-model="shortageCompletionDialogVisible"
      :po-list="poList"
      @refresh="queryClick"
    />
    <ChangeDelivery
      v-model="changeDeliveryVisible"
      :list="sidvoList"
      @refresh="queryClick"
    />
    <el-dialog
      title="合同模板选择"
      :close-on-click-modal="false"
      :visible.sync="contractTemplateVisible"
      width="600px"
    >
      <el-form
        ref="contractTemplateForm"
        label-position="right"
        label-width="110px"
        :model="contractTemplateForm"
      >
        <el-form-item
          label="合同模板："
          prop="templateType"
          :rules="{ required: true, message: '必填', trigger: 'change' }"
        >
          <el-select
            v-model="contractTemplateForm.templateType"
            style="width:100%;"
            value-key="id"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in commodityDict"
              :key="index"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        :data="contractTemplateTableData"
        max-height="550"
        border
        style="width: 100%"
      >
        <el-table-column prop="index" align="center" label="序号" width="60">
          <template #default="{$index}">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="purchaseOrderCode"
          align="center"
          label="采购订单"
          width="180"
        />

        <el-table-column align="center">
          <template #header>
            <i style="color:red">*</i> 出货日期
          </template>
          <template #default="{row}">
            <el-date-picker
              v-model="row.estiDeliveryTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="contractTemplateVisible = false"
        >取 消</el-button>
        <el-button
          type="primary"
          :loading="contractTemplateLoading"
          @click="contractTemplateSure"
        >生 成</el-button>
      </span>
    </el-dialog>
    <ImportFileDialog
      v-model="uploadDeliveryVisible"
      :show-file-list="true"
      :limit="1"
      :types="['xlsx', 'xls']"
      :import-view-api="deliveryFeedbackImportView"
      :import-submit-api="deliveryFeedbackImport"
      :import-details-columns="deliveryDetailsColumns"
      @handleDownload="downTemplate"
      @update="_orderpage"
    />
    <ShowDetails
      v-model="showDetails"
      :columns="type === 'delivery' ? detailColumns : ltColumns"
      :api="type === 'delivery' ? VendorDeliveryFeedback : listLtSubstandardLog"
      :row="editRow"
      :title="title"
    />
    <PackageImport v-model="packageDialogVisible" />
    <!-- 待办事项 -->
    <ToDoList
      v-model="toDoVisible"
      @refresh="queryClick"
      @update="getNumBacklog"
    />
    <!-- 回滚订单 -->
    <TurnBack v-model="backDialogVisible" :row="editRow" @refresh="queryClick" />
    <!-- 订单标签导入 -->
    <UploadExcel v-model="uploadDialogVisible" error-message-key="errorMsgList" :import-file-view="orderTagImportView" :import-commit="orderTagImportSubmit" columns-key="OrderTagImport" download-template-params="OrderTagImport" @refresh="queryClick" />
    <!-- 取消是否发送邮件 -->
    <CancelSendEmail v-model="cancelEmailVisible" :row="editRow" />
  </div>
</template>
<script>
import {
  queryVendorList, orderTagImportSubmit, orderTagImportView,
  deliveryFeedbackImportView,
  deliveryFeedbackImport,
  VendorDeliveryFeedback,
  listLtSubstandardLog,
  findDownloadUrl,
  findVendorDownloadUrl,
  listPlatFormSiteNumber,
  exportContract,
  allocationVendorProd,
  allocationVendorProdView,
  toVendorProdView,
  importVendorProdView,
  queryWarehouseList,
  orderpage,
  deleteorderid,
  planDeliveryDateImportView,
  planDeliveryDateImport,
  cancelPurchaseOrder,
  batchPrintShoesCode, shoesBatchNoImport, shoesBatchNoImportView, createOrderContractCheck, createOrderContract, getFeedbackCount, StockChannelList
} from '@/api/scm-api'
import { exportPurchaseOrderDetail, exportPurchaseOrder } from '@/api/export'
import Paging from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { downloads, handlePageParams } from '@/utils'
import ImportFileDialog from '@/components/ImportFileDialog'
import { detailColumns, ltColumns } from './columns.js'
import XEUtils from 'xe-utils'
import { batchApplyDeposit } from '@/api/deposit-api'
import { cloneDeep } from 'lodash'
import ImportFile from '@/components/ImportFile'
import Select from '@/components/Selection'
import UploadExcel from '@/components/Upload/UploadExcel.vue'

import {
  shoesBatchDetailsColumns,
  planDetailsColumns,
  deliveryDetailsColumns,
  VenndorConfirmColumns
} from '@/constant/tablecolumns'
import dayjs from 'dayjs'
import { styleListConfig, buListConfig, getBaseEnablecategory } from '@/api/configuration'
import { getBacklogCount } from '@/api/purchaseBacklog'
import {
  SendImport,
  ReceiveLog,
  DeliveryConfirmation,
  ChangeDelivery,
  DeliveryFeedback,
  ToDoList,
  ShortageCompletionForm,
  PackageImport,
  DownloadShoesPdf,
  ShowDetails,
  TurnBack,
  CancelSendEmail
} from './components/index'
export default {
  components: {
    TurnBack,
    ImportFile,
    DownloadShoesPdf,
    DeliveryFeedback,
    ShortageCompletionForm,
    Paging,
    SendImport,
    ReceiveLog,
    Select,
    ChangeDelivery,
    DeliveryConfirmation,
    ImportFileDialog,
    ShowDetails,
    PackageImport,
    ToDoList, UploadExcel,
    CancelSendEmail
  },
  filters: {
    transToValue(val, index) {
      return val[index]
    }
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      orderTagImportSubmit, orderTagImportView,
      uploadDialogVisible: false,
      backDialogVisible: false,
      cancelEmailVisible: false,
      exportLoading: false,
      title: '',
      ltColumns,
      VendorDeliveryFeedback,
      listLtSubstandardLog,
      packageDialogVisible: false,
      toDoVisible: false,
      isPurchaseDepartment: false,
      deliveryFeedbackImport,
      deliveryFeedbackImportView,
      changeDeliveryVisible: false,
      importDeliveryData: [],
      importPlanData: [],
      importVendorData: [],
      uploadTablePlanVisible: false,
      uploadTableVendorVisible: false,
      importSubmitLoading: false,
      shoesBatchDetailsColumns,
      planDetailsColumns,
      deliveryDetailsColumns,
      VenndorConfirmColumns,
      vendorDate: 0,
      predictDeliveryDate: '',
      contractTemplateLoading: false,
      contractTemplateVisible: false,
      contractTemplateForm: {
        templateType: ''
      },
      uploadTableShoesVisible: false,
      uploadDeliveryVisible: false,
      importShoesData: [],
      allocationVendorLoading: false,
      poList: [],
      arr: [],
      shortageCompletionDialogVisible: false,
      receiveLogDialog: false,
      sendImportDialog: false,
      deliveryDialog: false,
      tableHeight: '750px',
      records: [],
      rate: '',
      quantity: 0,
      type: '',
      codes: '',
      groupoptions: [],
      StockChannelOptions: [],
      useroptions: [],
      compareTime: false,
      showDetails: false,
      ruleForm: {
        cancelReason: ''
      },
      cancelParams: {},
      cancelDialogFormVisible: false, // 取消订单
      dialogselectlist: [],
      sidvoList: [],
      file: '',
      fileList: [],
      warehouseCode: '',
      dialogVisible: false,
      upLoding: false,
      vendordialogFormVisible: false,
      orderDetailDialog: false,
      TableLoading: false,
      editRow: {},
      warehouseOption: [],
      showRow: false, // 切换显示input
      tableDatas: [],
      VendortableDatas: [],
      vendorOptions: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      form: {
        orderStatus: [],
        vendorId: [],
        qualityStatus: [],
        style: [],
        purchaseOrderCode: '',
        purchasePlanCode: '',
        value1: [],
        value2: [],
        value3: [],
        operatorUser: '',
        seasonIdStr: [],
        positionIdStr: [],
        insideLabelDictList: [],
        categoryId: []
      },
      purchaseOrderCode: '',
      shoes: [],
      exportOrder: '',
      PlatFormAndSiteData: [],
      platformSiteNumberList: [],
      usedStockData: [],
      styleOptions: [],
      contractTemplateTableData: [],
      cancelrules: {
        cancelReason: [
          { required: true, message: this.$t('page.required'), trigger: 'blur' }
        ]
      },
      detailColumns,
      feedbackCount: '',
      channel: true, // 展示渠道下单量
      showClass: true,
      getNumLog: 0,
      basecategorydatas: []
    }
  },
  computed: {
    queryParameter() {
      return handlePageParams({
        formData: this.form,
        processedParameter: {
          timeField: {
            value1: ['startCreateTime', 'endCreateTime'],
            value2: ['startPlanDeliveryDate', 'endPlanDeliveryDate'],
            value3: ['startProduceProgressDate', 'endProduceProgressDate']
          },
          lastCategoryIdList: ['categoryId'],
          toStringList: ['orderStatus', 'vendorId', 'positionIdStr', 'qualityStatus', 'seasonIdStr', 'style', 'stockChannelIdStr', 'categoryId'],
          preTextArr: { purchaseOrderCode: false, stylePartnerCodeList: true, productNameList: true }
        }

      })
    },
    exportParameter() {
      return Object.assign(this.queryParameter, {
        isPurchaseDepartment: this.isPurchaseDepartment,
        insideLabelDictList: this.form.insideLabelDictList.length !== 0 ? [this.form.insideLabelDictList] : []
      })
    },
    quantityStyle() {
      return {
        info: this.quantity === 0,
        primary: this.quantity > 0
      }
    },
    commodityDict() {
      return this._getAllCommodityDict('PO_CONTRACT_TEMPLATE_TYPE').filter(
        item => item.val !== '2' && item.val !== '4'
      )
    }
  },
  watch: {
    contractTemplateVisible(val) {
      if (!val) {
        this.contractTemplateForm.templateType = ''
        this.$refs.contractTemplateForm.clearValidate()
      } else {
        this.$refs.contractTemplateForm &&
          this.$refs.contractTemplateForm.clearValidate()
      }
    }
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.multipleTable?.recalculate()
    })
  },
  mounted() {
    const defaultForm = this.$store.getters.purchaseOrderForm || {}
    this.form = Object.assign({}, this.$options.data.call(this).form, defaultForm)
    this._queryStyleList()
    this._queryBuList()
    this._queryWarehouseList()
    this._queryVendorList()
    this._StockChannelList()
    this._getStockNumber()
    this._orderpage()
    this._getBaseEnablecategory()
    const showClassValue = JSON.parse(localStorage.getItem('purchasbusiness'))
    this.showClass = showClassValue === null ? true : showClassValue
    this.channel = showClassValue === null ? true : showClassValue
    this.getNumBacklog()
  },

  methods: {
    showShortageButton(row) {
      return (
        row.orderStatus === 2 ||
        (row.orderStatus === 3 && row.unclearedPairs) ||
        (row.shortageReceiptQuantity > 0 && row.orderStatus === 5)
      )
    },
    async _getFeedbackCount() {
      const { datas } = await getFeedbackCount()
      this.feedbackCount = datas || ''
    },
    handleChangeDelivery() {
      if (this.sidvoList.length) {
        if (this.sidvoList.some(item => item.contractCode || ['7', '8', '9', '10'].includes(item.orderStatus + ''))) {
          return this.$message({
            message: '选中的所有采购订单必须都没有关联采购订单合同,且订单状态不能为：已取消、已作废、审批中、审批不通过',
            type: 'warning'
          })
        }
        this.changeDeliveryVisible = true
      } else {
        this.$message({
          message: '请至少选择一条采购单',
          type: 'warning'
        })
      }
    },
    async submitShoesBatchDatas() {
      try {
        this.importSubmitLoading = true
        const { code, msg } = await shoesBatchNoImport(this.dialogselectlist)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.uploadTableShoesVisible = false
          this.queryClick()
        }
      } finally {
        this.importSubmitLoading = false
      }
    },
    async submitPlanDatas() {
      try {
        this.importSubmitLoading = true
        const { code, msg } = await planDeliveryDateImport(
          this.dialogselectlist
        )
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.uploadTablePlanVisible = false
          this.queryClick()
        }
      } finally {
        this.importSubmitLoading = false
      }
    },
    async submitVendorDatas() {
      try {
        this.importSubmitLoading = true
        this.$notify({
          message: '操作成功',
          type: 'success'
        })
        this.VendortableDatas = this.VendortableDatas.map((item, index) => {
          // 判断Po相等且不存在错误信息
          const matchingImportData = this.dialogselectlist.find(
            importItem =>
              importItem.purchaseOrderCode === item.purchaseOrderCode &&
              !importItem.errorMsg
          )
          if (matchingImportData) {
            return { ...item, ...matchingImportData }
          }
          return item
        })
        this.uploadTableVendorVisible = false
        // this.queryClick()
      } finally {
        this.importSubmitLoading = false
      }
    },

    editPackage(row) {
      this.editRow = row
      this.receiveLogDialog = true
    },
    contractTemplateSure() {
      this.$refs.contractTemplateForm.validate(async valid => {
        if (valid) {
          const isEstiDeliveryTime = this.contractTemplateTableData.some(
            item => !item.estiDeliveryTime
          )
          if (isEstiDeliveryTime) {
            this.$message.warning('请选择出货日期')
            return
          }
          try {
            this.contractTemplateLoading = true
            const orderCodeList = this.contractTemplateTableData.map(item => {
              const { purchaseOrderCode, id, estiDeliveryTime } = item
              return {
                purchaseOrderCode,
                id,
                estiDeliveryTime: dayjs(estiDeliveryTime).format('YYYY-MM-DD')
              }
            })
            const { code, msg } = await createOrderContract(
              this.contractTemplateForm.templateType,
              orderCodeList
            )
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.contractTemplateVisible = false
              this._orderpage()
            }
          } finally {
            this.contractTemplateLoading = false
          }
        }
      })
    },
    async handleContract() {
      if (this.sidvoList.length) {
        if (this.sidvoList.some(item => item.orderStatus === 7 || item.orderStatus === 8)) {
          this.$message({
            message: '审批中、审批不通过的采购订单不允许操作！',
            type: 'warning'
          })
          return false
        }
        const orderCodeList = []
        if (
          this.sidvoList.some(
            item =>
              item.orderStatus !== 4 && item.orderStatus !== 3 && item.orderStatus !== 2 && item.orderStatus !== 5 ||
              !item.receiptPlaceName ||
              !item.deliveryMethod
          )
        ) {
          this.$message({
            message:
              '请选择已分配供应商生产、已转收货、已完成、缺量收货状态采购单,且送货方式、收货地点不允许为空',
            type: 'warning'
          })
          return false
        }
        const { deliveryMethod, receiptPlaceName } = this.sidvoList[0]
        if (
          this.sidvoList.some(
            item =>
              item.receiptPlaceName !== receiptPlaceName ||
              item.deliveryMethod !== deliveryMethod
          )
        ) {
          return this.$message({
            message:
              '选择的采购订单其送货方式、收货地点不完全一致，无法生成采购订单合同，请确认',
            type: 'warning'
          })
        }
        this.sidvoList.map(item => orderCodeList.push(item.purchaseOrderCode))
        const { code } = await createOrderContractCheck(orderCodeList)
        if (code === 0) {
          this.contractTemplateVisible = true
          this.contractTemplateTableData = cloneDeep(this.sidvoList)
        }
      } else {
        this.$message({
          message: '请至少选择一条采购单',
          type: 'warning'
        })
      }
    },
    batchCompletion() {
      if (this.sidvoList.length === 0) {
        this.$message({
          message: '请至少选择一条采购单',
          type: 'warning'
        })
        return false
      }
      if (this.sidvoList.some(item => item.orderStatus === 7 || item.orderStatus === 8)) {
        this.$message({
          message: '不可操作审批中、审批不通过的采购订单！',
          type: 'warning'
        })
        return false
      }
      const arr = []
      let errorList = []
      let poList = []
      poList = this.sidvoList.filter(
        row =>
          row.orderStatus === 2 ||
          (row.orderStatus === 3 && row.unclearedPairs) ||
          (row.shortageReceiptQuantity > 0 && row.orderStatus === 5)
      )

      poList.map(item => {
        arr.push(item.purchaseOrderCode)
      })
      errorList = this.sidvoList
        .filter(item => !arr.includes(item.purchaseOrderCode))
        .map(item => item.purchaseOrderCode)
      if (errorList.length) {
        // 不符合条件的confirm提示
        const newDatas = []
        const h = this.$createElement
        for (const i in errorList) {
          newDatas.push(h('p', { style: 'color: red' }, errorList[i]))
        }
        newDatas.push(
          h(
            'p',
            null,
            '未转收货，不能执行【批量缺量收货】操作，请取消勾选后再做批量操作！'
          )
        )
        this.$confirm('提示', {
          title: '提示',
          message: h('div', null, newDatas),
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {})
      } else {
        this.poList = poList.map(item => {
          return {
            id: item.id,
            purchaseOrderCode: item.purchaseOrderCode,
            groupStyle: item.groupStyle,
            shortageReceiptQuantity: item.shortageReceiptQuantity
          }
        })
        this.shortageCompletionDialogVisible = true
      }
    },

    _shortageCompletion({
      id,
      purchaseOrderCode,
      groupStyle,
      shortageReceiptQuantity
    }) {
      this.poList = []
      this.poList.push({
        id,
        purchaseOrderCode,
        groupStyle,
        shortageReceiptQuantity
      })
      this.shortageCompletionDialogVisible = true
    },
    async _batchPrintShoesCode() {
      const purchaseOrderCode = []
      this.sidvoList.map(e => {
        purchaseOrderCode.push(e.purchaseOrderCode)
      })
      if (this.sidvoList.length === 0) {
        this.$message({
          message: '请至少选择一条采购单',
          type: 'warning'
        })
        return false
      }
      const { code, msg } = await batchPrintShoesCode({
        flag: 2,
        purchaseOrderCode: purchaseOrderCode.join(',')
      })
      if (code === 0) {
        this.$notify({
          message: msg,
          type: 'success'
        })
        this.clearSelection()
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this._orderpage()
    },
    handleTable() {
      if (this.records.length === 0) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return false
      }
      if (this.rate) {
        this.records.map(item => {
          this.$set(
            item,
            'deposit',
            Number((this.rate * item.totalPrice) / 100).toFixed(2)
          )
        })
      } else {
        const deposit = this.records[0].deposit
        this.records.map(item => {
          item.deposit = deposit
        })
      }
    },
    checkorder() {
      if (this.records.length === 0) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return false
      }
      this.$confirm('确定要修改定金吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async() => {
        let arr = JSON.parse(JSON.stringify(this.records))
        arr = arr.map(item => ({
          purchaseOrderCode: item.purchaseOrderCode,
          deposit: item.deposit
        }))
        const { code } = await batchApplyDeposit(arr)
        if (code === 0) {
          this.$message.success('操作成功') && this.queryClick()
          this.rate = ''
        }
      })
    },
    input(row) {
      row.deposit > 0
        ? this.$refs.multipleTable.setCheckboxRow(row, true)
        : this.$refs.multipleTable.setCheckboxRow(row, false)
      this.records = this.$refs.multipleTable.getCheckboxRecords()
    },
    clearSelection() {
      this.$refs.multipleTable.clearCheckboxRow()
      this.records = []
      this.sidvoList = []
      this.quantity = 0
    },

    editVenname() {
      if (this.sidvoList.length === 0) {
        this.$message({
          message: '请至少选择一条采购单',
          type: 'warning'
        })
        return false
      }
      if (this.sidvoList.some(item => item.orderStatus === 2)) {
        this.$message({
          message: `已转收货状态不可修改供应商`,
          type: 'warning'
        })
        return false
      }
      this.editVennameDialog = true
    },
    changeBU(val) {
      this.useroptions = []
      this.form.operatorUser = ''
      this.useroptions = this.groupoptions.find(
        item => item.groupName === val
      )?.list
    },
    async _queryBuList() {
      const { datas } = await buListConfig()
      this.groupoptions = datas
      this.groupoptions.map(item => {
        item.list.map(i => this.useroptions.push(i))
      })
      this.useroptions = Array.from(new Set(this.useroptions))
    },

    importDateDialogSubmit() {
      this.$refs.uploadDateForm.submit()
    },
    comparedate(date1, date2) {
      var oDate1 = new Date(date1)
      var oDate2 = new Date(date2)
      if (oDate1.getTime() > oDate2.getTime()) {
        return true
      } else {
        return false
      }
    },
    select_status() {
      this.$forceUpdate()
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index >= 12 && index <= 21) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              prev =
                (prev + '').indexOf('.') === -1
                  ? prev
                  : Number(prev.toFixed(2))
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    tableRowClassNames({ row, rowIndex }) {
      if (row.errorMsg) {
        return 'warning-row'
      }
      return ''
    },
    // 模板下载
    async downTemplate() {
      const { datas } = await findDownloadUrl(
        'DELIVERY_FEEDBACK_IMPORT_TEMPLATE'
      )
      downloads(datas)
    },
    // 点击查询获取信息
    queryClick(page) {
      this.pager.current = 1
      this._orderpage()
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await styleListConfig()
      this.styleOptions = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 默认查询
    async _orderpage(pager) {
      try {
        this.TableLoading = true
        const params = Object.assign({}, this.queryParameter, this.pager)
        const {
          datas: { records, pager }
        } = await orderpage(params)
        this.tableDatas = records.map(item => {
          return {
            ...item,
            createTime: item.createTime && item.createTime.split(' ')[0],
            deliveryMethodI18: item.deliveryMethod ? item.deliveryMethodI18 : ''
          }
        })
        this.isPurchaseDepartment =
          this.tableDatas[0]?.isPurchaseDepartment || false
        this.pager = pager
        this._getPlatFormAndSiteNumber()
        // document.querySelector('#myTable').style.height = +this.tableHeight + 1 + 'px'
        this.$nextTick(() => {
          this.$refs.multipleTable?.recalculate()
        })
      } finally {
        this.TableLoading = false
        this.clearSelection()
        this._getFeedbackCount()
        this.$store.dispatch('saveForm/savePurchaseOrder', this.form)
      }
    },
    // 获取平台站点
    async _getPlatFormAndSiteNumber() {
      const purchaseOrderCode = []
      this.tableDatas.map(item => {
        purchaseOrderCode.push(item.purchaseOrderCode)
      })
      const listPlatFormSiteNumberParams = Object.assign({
        purchaseOrderCode: purchaseOrderCode.toString() || ''
      })
      const { datas } = await listPlatFormSiteNumber(
        listPlatFormSiteNumberParams
      )
      this.PlatFormAndSiteData = datas
      this.platformSiteNumberList = datas.platformSiteNumberList
      var arr = []
      this.tableDatas.map(e => {
        this.platformSiteNumberList.map(v => {
          if (e.purchaseOrderCode === v.purchaseOrderCode) {
            const obj = JSON.parse(JSON.stringify(v))
            delete obj.purchaseOrderCode
            e = { ...e, ...obj }
          }
        })
        arr.push(e)
      })
      this.tableDatas = arr
    },
    // 获取启用状态下备货渠道
    async _getStockNumber() {
      const { datas } = await StockChannelList({ status: 1 })
      this.usedStockData = datas.map(item => item.channelName)
    },
    // 仓库
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList({
        warehouseType: 1,
        warehouseLocation: 1
      })
      this.warehouseOption = datas
    },
    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._orderpage()
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._orderpage()
    },
    selectChangeEvent({ checked, records }) {
      let data = ''
      this.records = records
      this.sidvoList = []
      for (let i = 0; i < records.length; i++) {
        data = records[i]
        this.sidvoList.push(data)
      }
      this.quantity = this.sidvoList.length
    },
    async handleExport(type) {
      if (type === 'detail') {
        const canExport = Object.values(this.form)?.find(item => item?.length)
        if (!canExport) return this.$message.warning('必须输入至少一个筛选条件才能导出明细')
      }
      try {
        this.exportLoading = true
        type === 'detail' ? await exportPurchaseOrderDetail(this.exportParameter) : await exportPurchaseOrder(this.exportParameter)
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
      } finally {
        this.exportLoading = false
      }
    },

    handleChangeCode(val) {
      this.warehouseCode = val.warehouseCode
    },

    // 根据ID删除订单信息
    deleteorder(row) {
      this.$confirm('确定要作废采购订单么', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this._deleteorderid(row)
      })
    },
    // 通过id删除订单信息
    async _deleteorderid(row) {
      const { msg } = await deleteorderid(row)
      this.$notify({
        title: msg,
        message: msg,
        type: 'success'
      })
      this._orderpage()
    },
    clodeCancelDialog() {
      this.$refs.ruleForm.resetFields()
      this.ruleForm.cancelReason = ''
    },
    // 取消订单
    cancelOrder(purchaseOrderCode, cancelRole, orderStatus) {
      if (orderStatus === 4) {
        this.cancelEmailVisible = true
        this.editRow = Object.assign({}, { purchaseOrderCode, orderStatus, cancelRole })
      } else {
        this.cancelDialogFormVisible = true
        this.cancelParams = Object.assign({}, { cancelRole, purchaseOrderCode })
      }
    },
    cancelOrderSure() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          this.cancelParams.cancelReason = this.ruleForm.cancelReason
          const { msg, code } = await cancelPurchaseOrder(this.cancelParams)
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          code === 0 ? (this.cancelDialogFormVisible = false) : ''
          this._orderpage()
        }
      })
    },
    // 回滚订单
    backOrder({ purchaseOrderCode }) {
      this.editRow = { purchaseOrderCode }
      this.backDialogVisible = true
    },
    vieworder(row) {
      this.$router.push({
        path: 'purchdetail',
        append: true,
        query: { id: row.id }
      })
    },
    //  查询交期反馈
    Vendororder(row) {
      this.editRow = row
      this.vendordialogFormVisible = true
    },

    // 供应商交期时间记录查询
    ClickEvent(type, row) {
      this.editRow = row
      this.type = type
      this.title = type === 'delivery' ? '采购反馈到货日期' : 'LT不达标原因'
      this.showDetails = true
    },

    footerMethod({ columns, data }) {
      const arr = this.PlatFormAndSiteData.platformSiteList
      const sum = columns.map((column, columnIndex) => {
        if (columnIndex === 0) {
          return '汇总'
        }
        if (
          (arr && arr.length && arr.includes(column.property)) ||
          [
            'unclearedPairs',
            'shortageReceiptQuantity',
            'totalNumber',
            'collectNumber',
            'totalPrice',
            'returnNumber'
          ].includes(column.property)
        ) {
          if ((XEUtils.sum(data, column.property) + '').indexOf('.') !== -1) {
            return XEUtils.sum(data, column.property).toFixed(2)
          } else {
            return XEUtils.sum(data, column.property)
              ? XEUtils.sum(data, column.property)
              : ''
          }
        }
        return ' '
      })
      return [sum]
    },
    async Ordervendor(row) {
      // 仅“已生效”状态PO可选中进行分配供应商生产操作
      if (!this.sidvoList.length || this.sidvoList.some(item => item.orderStatus !== 1)) {
        this.$message({
          message: '请选中”已生效“状态采购订单！',
          type: 'warning'
        })
        return
      }
      // 校验采购框架合同
      const purchaseOrderCode = this.sidvoList.map(
        item => item.purchaseOrderCode
      )
      const { datas } = await toVendorProdView(purchaseOrderCode)
      if (!datas || datas.length <= 0) {
        return this.getAllocationVendor(this.sidvoList)
      } else {
        const vendorNameList = datas.map(item => item.vendorName)
        const cargoOwnerNameList = datas.map(item => item.cargoOwnerName)
        this.$confirm(
          `${vendorNameList.join('、')}供应商，${cargoOwnerNameList.join(
            '、'
          )}主体，未找到有效的采购框架合同，是否继续分配供应商生产?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(async() => {
            this.getAllocationVendor(this.sidvoList)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      }
    },
    async getAllocationVendor(data) {
      this.vendorDate = 0
      this.dialogVisible = true
      // 修改
      const poList = data.map(item => {
        const { purchaseOrderCode } = item
        return purchaseOrderCode
      })
      const purchaseOrderCodeList = { purchaseOrderCodeList: poList }
      // 调用预览接口
      const { datas } = await allocationVendorProdView(purchaseOrderCodeList)
      this.VendortableDatas = datas.map(item =>
        Object.assign(item, { estiDeliveryTime: item.predictDeliveryDate })
      )
    },
    // 修改日期
    editDay() {
      // 需求交货日期=预计交货日期+需求天数estiDeliveryTime
      this.VendortableDatas = this.VendortableDatas.map(item =>
        Object.assign(item, {
          estiDeliveryTime: dayjs(item.predictDeliveryDate)
            .add(this.vendorDate, 'day')
            .format('YYYY-MM-DD')
        })
      )
    },

    save() {
      this.sidvoList.map((item, index) => {
        this.VendortableDatas.map(list => {
          if (list.purchaseOrderCode === item.purchaseOrderCode) {
            Object.assign(item, {
              estiDeliveryTime: list.estiDeliveryTime,
              ids: item.id
            })
          }
        })
        // const date = this.VendortableDatas[index].estiDeliveryTime
        // return { ids: id, purchaseOrderCode, estiDeliveryTime: date }
      })
      this._allocationVendorProd(this.sidvoList)
    },
    async _allocationVendorProd(data) {
      try {
        this.allocationVendorLoading = true
        // 调用原有接口，提醒分配成功
        const { code, msg, datas } = await allocationVendorProd(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message:
              '分配成功' +
              datas.successSize +
              '条,' +
              '分配失败' +
              datas.failSize +
              '条',
            type: 'success'
          })
          this.dialogVisible = false
          this._orderpage()
        } else {
          // 其他状态
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
      } finally {
        this.allocationVendorLoading = false
      }
    },
    // 合同导出
    exportContractdata(str, orderStatus) {
      // if (orderStatus === 1) {
      //   return this.$message.warning(
      //     str + '状态为已生效   不允许打印合同和导出合同'
      //   )
      // }
      this.purchaseOrderCode = str
      this._exportContract(this.purchaseOrderCode)
    },
    async _exportContract(str) {
      const { datas } = await exportContract(str)
      this.exportOrder = datas
      this.exportOrder.map(e => {
        window.open(e)
      })
    },
    // 导入
    beforeUpload(file) {
      const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error(this.$t('page.UploadSizeTips'))
        return false
      }
      if (Xls[Xls.length - 1] === 'xls' || Xls[Xls.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message.error(this.$t('page.UploadTypeTips'))
        return false
      }
    },

    async uploadShoesBatchFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { datas, code, msg } = await shoesBatchNoImportView(form)
        this.importShoesData = datas
        code === 0 ? (this.uploadTableShoesVisible = true) : ''
        this.$notify({
          message: msg,
          type: 'success'
        })
        this.file = ''
        this.fileList = []
      } finally {
        this.$refs.uploadShoesForm.clearFiles()
      }
    },
    async uploadPlanFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { datas, code, msg } = await planDeliveryDateImportView(form)
        this.importPlanData = datas
        code === 0 ? (this.uploadTablePlanVisible = true) : ''
        this.$notify({
          message: msg,
          type: 'success'
        })
        this.file = ''
        this.fileList = []
      } finally {
        this.$refs.uploadPlanForm.clearFiles()
      }
    },
    async uploadVendorFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const poArray = this.sidvoList.map(item => item.purchaseOrderCode)
        const { datas } = await importVendorProdView(form, poArray)
        this.importVendorData = datas
        // this.VendortableDatas = Object.assign({}, this.VendortableDatas, this.importVendorData)
        this.uploadTableVendorVisible = true
        this.$notify({
          message: '操作成功',
          type: 'success'
        })
        // 需要再次上传
        this.file = ''
        this.fileList = []
      } finally {
        this.$refs.uploadVendorForm.clearFiles()
      }
    },
    dialogSelectionChange(val) {
      this.dialogselectlist = val
    },
    handleDeletedetails() {
      const data = this.$refs.uploadTableDataRef.selection
      data.map(e => {
        var index = this.importdata.findIndex(i => {
          return e.sku === i.sku
        })
        if (index !== -1) {
          this.importdata.splice(index, 1)
        }
      })
    },
    listDetailByPrint() {
      if (this.sidvoList.length === 0) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return false
      }
      const codes = []
      for (var i = 0; i < this.sidvoList.length; i++) {
        codes.push(this.sidvoList[i].purchaseOrderCode)
      }
      this.orderDetailDialog = true
      this.codes = codes.join(',')
    },
    // 展示渠道下单量
    showChannel() {
      this.showClass = !this.showClass
      localStorage.setItem('purchasbusiness', this.showClass.toString())
    },
    // 分配供应商生产模板
    async downVendorTemplate() {
      const { datas } = await findVendorDownloadUrl(
        'allocationVendorProdImport'
      )
      downloads(datas)
    },
    // 备货渠道下拉框
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    // disabledDateMethod(params) {
    //   const { date } = params
    //   const dd = date.getDate()
    //   return dd > 15
    // }
    // 获取待办事项
    async getNumBacklog() {
      const { datas } = await getBacklogCount()
      this.getNumLog = datas
    },
    // 获取style品类
    async _getBaseEnablecategory() {
      const { datas } = await getBaseEnablecategory()
      this.basecategorydatas = datas
    }
  }
}
</script>
<style lang="scss" scope>
.vendor-from {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-table .warning-row {
  color: red !important;
}

.specialColor {
  color: red;
}
.specialColorDefault {
  color: #1890ff;
}
.el-tooltip__popper {
  max-width: 20%;
}

.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  background: #e6e6e6 !important;
  color: #303133 !important;
}
.order {
  .el-range-input {
    width: 90px !important;
  }
  .el-range-separator {
    width: 6% !important;
  }
  .el-date-editor {
    width: 100% !important;
  }
}
.select-info {
  border-radius: 6px;
}
.primary {
  background-color: #e6f7ff;
  border: 1px solid #bae7ff;
  .el-icon-info {
    color: #1890ff;
  }
}
.info {
  background-color: #f4f4f5;
  border: 1px solid #dcdcdc;
  .i {
    color: #909399;
  }
}
/deep/.table-fixed {
  .vxe-table--fixed-right-wrapper .scrolling--middle {
    height: 692px !important;
  }
}

.header_btn {
  display: flex;
  flex-wrap: wrap;
  > button {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  > div {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .el-badge__content.is-fixed {
    right: 20px !important;
  }
}
.inline-block {
  display: inline-block;
  margin: 20px;
}
.notification-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  .updateCount {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -5px;
  }
  .updateText {
    width: 220px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-right: 10px;
  }
}
</style>
