<template>
  <div>
    <el-dialog title="导入发货单" :visible.sync="visible" width="550px" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="到货仓库" prop="arrivalWarehouseId">
          <Select
            v-model="form.arrivalWarehouseId"
            api-key="getWarehouse"
            :configuration="{
              key: 'id',
              label: 'warehouseName',
              value: 'id'
            }"
            clearable
            @responseData="e=>warehouseList=e"
          />
        </el-form-item>
        <el-form-item label="发货单" prop="file">
          <el-upload
            ref="uploadForm"
            action=""
            accept=".xlsx, .xls"
            :http-request="uploadDateFile"
            :limit="1"
            :file-list="fileList"
            :auto-upload="false"
            :on-change="fileDateChange"
          >
            <el-button type="primary">导入文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="text" @click="downTemplate">模板下载</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取 消</el-button>
        <el-button type="primary" :disabled="flag" @click="handleImport($event)">导入预览</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="uploadTableDateVisible" width="90%" title="导入详情" append-to-body :close-on-click-modal="false">

      <el-switch
        v-model="onlyError"
        active-text="仅查看错误信息"
        @change="handleSwitchChange"
      />
      <el-table
        ref="uploadTableDateRef"
        :data="importDateData"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="450px"
        class="mt-3"
        :cell-class-name="tableRowClassNames"
        @selection-change="dialogSelectionChange"
      >
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column :label="$t('page.No')" prop="index" width="80" align="center" />
        <el-table-column label="错误信息" min-width="140">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.errorMsgList" :key="index">{{ item.errorMsg }}
              <el-button
                v-if="item.errorType&&item.errorType==='overCollect'"
                type="text"
                @click="handleSolution(scope.row)"
              >查看其他解决方案</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="vendorName" label="供应商" />
        <el-table-column prop="carNumber" label="车牌号" />
        <el-table-column prop="expectArrivalWarehouseDate" label="预计到仓时间" width="160" />
        <el-table-column prop="purchaseOrderCode" label="订单号" />
        <el-table-column prop="style" label="Style" />
        <el-table-column prop="fnskupc" label="条形码" />
        <el-table-column prop="color" label="颜色" />
        <el-table-column prop="size" label="尺码" />
        <el-table-column prop="boxNumber" label="双/箱" />
        <el-table-column prop="deliveryPairs" label="送货双数" />
        <el-table-column prop="deliveryQuantity" label="送货箱数" />
      </el-table>
      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="uploadTableDateVisible = false">取消</el-button>
        <el-button type="primary" :loading="loading" @click="submitImportDateTable">提交</el-button>
      </el-row>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="90%"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="ruleForm">
        <div v-if="ruleForm.delayList.length">
          <span>以下PO未准交，需维护未准交原因：</span>
          <el-table
            :data="ruleForm.delayList"
            :header-cell-style="{ background: '#fafafa' }"
            tooltip-effect="dark"
            max-height="500px"
          >
            <el-table-column prop="purchaseOrderCode" label="采购订单号" min-width="160" />
            <el-table-column prop="styleName" label="Style" min-width="160" />
            <el-table-column prop="seasonI18" label="季节" min-width="160" />
            <el-table-column prop="vendorDeliveryFeedback" label="供应商交期" min-width="160" />
            <el-table-column prop="planDeliveryDate" label="计划评审到货日期" min-width="160" />
            <el-table-column label="未准交原因" align="center" min-width="260">
              <template slot="header">
                <p><span style="color: red">*</span>未准交原因</p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="`delayList.${scope.$index}.deliveryDelay`"
                  :rules=" {required: true, message: '必填', trigger: ['change'] }"
                >
                  <el-select
                    v-model="scope.row.deliveryDelay"
                    filterable
                    style="width:100%"
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >

                    <el-option
                      v-for="item in DelayReasonOptions"
                      :key="item.dictValue"
                      :label="item.dictNameJson['zh-CN']"
                      :value="Number(item.dictValue)"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="交期备注" align="center" min-width="200">
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    v-model="scope.row.deliveryRemark"
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 2}"
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div v-if="ruleForm.substandardList.length" class="mt-2">
          <span>以下PO的实际LT未达标，需维护LT未达标原因：</span>

          <el-table
            :data="ruleForm.substandardList"
            :header-cell-style="{ background: '#fafafa' }"
            tooltip-effect="dark"
            max-height="500px"
          >

            <el-table-column prop="purchaseOrderCode" label="采购订单号" min-width="160" />
            <el-table-column prop="styleName" label="Style" min-width="160" />
            <el-table-column prop="seasonI18" label="季节" min-width="160" />
            <el-table-column prop="expectedArrivalDate" label="预计到仓时间" min-width="160" />
            <el-table-column prop="orderDate" label="下单日期" min-width="160" />
            <el-table-column prop="executableLt" label="可执行LT" min-width="160" />
            <el-table-column prop="actualLt" label="实际LT" min-width="160" />
            <el-table-column label="LT不达标原因" align="center" min-width="200">
              <template slot="header">
                <p><span style="color: red">*</span>LT不达标原因</p>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :prop="`substandardList.${scope.$index}.substandardDict`"
                  :rules=" {required: true, message: '必填', trigger: ['change'] }"
                >
                  <el-select
                    v-model="scope.row.substandardDict"
                    style="width:100%"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option
                      v-for="item in LtReasonOptions"
                      :key="item.dictValue"
                      :label="item.dictNameJson['zh-CN']"
                      :value="item.dictValue"
                    />

                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="LT不达标备注" align="center" min-width="200">
              <template slot-scope="scope">
                <el-form-item>
                  <el-input
                    v-model="scope.row.substandardNote"
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 2}"
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handleDelayReasonsSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看其他解决方案"
      :visible.sync="solutionDialogVisible"
      width="90%"
      :close-on-click-modal="false"
    >

      <div>1.检查SKU是否有收货单未手动操作收货完成，导致收货占用数量 <el-tooltip class="item" effect="dark" content="当前SKU已导入发货单的数量，或已收货完成的数量" placement="top">
        <i class="el-icon-question" />
      </el-tooltip> 未释放</div>
      <Table
        :table-data="collectDetailList"
        :columns="collectDetailColumns"
        max-height="300"
      />

      <div>2.检查PO的退货返修待收情况（注：若此次收货为返修收货则导入发货单文件中的【PO】字段须填写“采购退货单号”）</div>
      <Table
        :table-data="returnGoodsOrderList"
        :columns="returnGoodsOrderColumns"
        max-height="300"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="solutionDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { findDownloadUrl, preDeliveryImport } from '@/api/scm-api'
import { deliverImport, deliverImportView, deliverImportVerify, overCollectSolution, getLtReason, getDelayReason } from '@/api/audit-api.js'
import { downloads } from '@/utils'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { cloneDeep } from 'lodash'
import Table from '@/components/Table'

export default {
  components: { Select, Table },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    }},
  data() {
    return {
      solutionDialogVisible: false,
      onlyError: false,
      list: [],
      flag: true,
      importDialog: false,
      importDateData: [],
      defaultList: [],
      uploadTableDateVisible: false,
      loading: false,
      submitLoading: false,
      form: {
        arrivalWarehouseId: ''
      },
      file: '',
      fileList: [],
      warehouseList: [],
      rules: {
        arrivalWarehouseId: { required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] }
      },
      ruleForm: {
        delayList: [],
        substandardList: []
      },
      dialogVisible: false,
      title: '',
      returnGoodsOrderList: [],
      collectDetailList: [],
      collectDetailColumns: [
        { label: 'PO号', prop: 'purchaseOrderCode' },
        { label: 'SKU', prop: 'sku' },
        { label: 'Style', prop: 'style' },
        { label: 'Color', prop: 'color' },
        { label: 'Size', prop: 'size' },
        { label: '收货单号', width: 200, prop: 'collectCode' },
        { label: '状态', prop: 'statusI18' },
        { label: '收货占用数量', prop: 'collectHoldPairs' },
        { label: '实收数量', prop: 'historyActualPairs' }
      ],
      returnGoodsOrderColumns: [
        { label: '采购退货单号',
          width: 180,
          formatter: (row) => {
            return <el-button onClick={() => this.handleClick(row.returnGoodsOrderId)} type='text'>{row.returnCode}</el-button>
          }
        },
        { label: 'PO号', prop: 'purchaseOrderCode' },
        { label: '供应商', prop: 'vendorName' },
        { label: 'Style', prop: 'style' },
        { label: '实际退货数量', prop: 'actualTotalNumber' },
        { label: '返修入库数量', prop: 'returnCollectNumber' },
        { label: '返修待收数量', prop: 'returnWaitCollectNumber' }

      ],
      LtReasonOptions: [],
      DelayReasonOptions: []
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    importParams() {
      const { arrivalWarehouseId } = this.form
      const { warehouseName, sourceWarehouseCode } = this.warehouseList.find(item => item.id === arrivalWarehouseId)
      return Object.assign({}, { arrivalWarehouseId,
        arrivalWarehouseName: warehouseName, arrivalWarehouseCode: sourceWarehouseCode },
      { list: this.list })
    },
    importDelayReasonsParams() {
      const { delayList, substandardList } = this.ruleForm
      return Object.assign({}, this.importParams, { delayList, substandardList })
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.handleClearFiles()
        })
      }
    },
    'dialogVisible'(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.ruleForm?.clearValidate()
        })
      }
    }
  },
  created() {

  },
  mounted() {
    this._getLtReason()
    this._getDelayReason()
  },
  methods: {
    handleClick(code) {
      const url = `${process.env.VUE_APP_SCM_API}purchasbusiness/purchasereturn/detail?id=${code}`
      window.open(url, '_blank')
    },
    async handleSolution(row) {
      const { realPurchaseOrderCode, purchaseOrderCode, sku } = row
      const { datas: { collectDetailList, returnGoodsOrderList }} = await overCollectSolution({ realPurchaseOrderCode, purchaseOrderCode, sku })
      this.collectDetailList = collectDetailList
      this.returnGoodsOrderList = returnGoodsOrderList
      this.solutionDialogVisible = true
    },
    handleSwitchChange(val) {
      const list = cloneDeep(this.defaultList)

      this.importDateData = val ? list.filter(item => (item.errorMsgList?.length)) : list
    },
    async downTemplate() {
      const { datas } = await findDownloadUrl('DELIVER_IMPORT_TEMPLATE')
      downloads(datas)
    },
    tableRowClassNames({ row, columnIndex }) {
      if (row.errorMsgList?.length && columnIndex === 2) {
        return 'warning-row'
      }
      return ''
    },
    dialogSelectionChange(val) {
      this.list = val
    },
    async submitImportDateTable() {
      if (!this.list.length) {
        this.$message.warning('请至少选中一条数据')
        return
      }
      if (this.list.find(item => item.errorMsgList?.length)) {
        this.$confirm('列表中有错误信息不允许提交', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      try {
        this.loading = true
        const { code, datas: { delayList = [], substandardList = [] }} = await preDeliveryImport(this.importParams)
        if (delayList?.length || substandardList?.length) {
          this.title = delayList?.length && substandardList?.length ? '维护未准交原因/LT不达标原因'
            : delayList?.length ? '维护未准交原因' : '维护LT不达标原因'
          this.ruleForm.delayList = delayList || []
          this.ruleForm.substandardList = substandardList || []
          this.dialogVisible = true
        } else {
          if (code === 0) {
            // this.$notify({
            //   message: '操作成功',
            //   type: 'success'
            // })
            // this.$emit('input', false)
            // this.uploadTableDateVisible = false
            // this.$emit('refresh')
            const { code, msg } = await deliverImport(this.importParams)
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$emit('input', false)
              this.uploadTableDateVisible = false
              this.$emit('refresh')
            }
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    // 新弹窗的确认
    handleDelayReasonsSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            const { code, msg } = await deliverImport(this.importDelayReasonsParams)
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$emit('input', false)
              this.uploadTableDateVisible = false
              this.dialogVisible = false
              this.$emit('refresh')
            }
          } finally {
            this.submitLoading = false
          }
        }
      })
    },
    beforeUpload(file) {
      const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('文件不能超出10M')
        return false
      }
      if (Xls[Xls.length - 1] === 'xls' || Xls[Xls.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message.error('只支持.xls与.xlsx导入')
        return false
      }
    },
    fileDateChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeUpload(file)) {
          this.flag = false
        }
      } else {
        this.flag = true
      }
    },
    async uploadDateFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { datas: verifyDatas } = await deliverImportVerify(form)
        console.log('datas: ', verifyDatas)
        const { datas, code } = await deliverImportView(form)
        // 0607新增【采购订单：POxxxx、POxxxx采购合同未生成，请是否继续导入发货单】
        if (Array.isArray(verifyDatas) && verifyDatas.length > 0) {
          this.$confirm(`采购订单：${verifyDatas.join(',')}采购合同未生成，请是否继续导入发货单`, '采购合同未生成提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleImportDatas(datas, code)
          }).catch(() => {
            this.file = ''
            this.fileList = []
          })
        } else {
          this.handleImportDatas(datas, code)
        }
      } catch (error) {
        console.log(((error)))
        const confirmText = String(error).slice(6).split('&hh')
        console.log(confirmText)
        const newDatas = []
        const h = this.$createElement
        for (const i in confirmText) {
          newDatas.push(h('p', null, confirmText[i]))
        }
        this.$confirm('提示', {
          title: '提示',
          message: h('div', null, newDatas),
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
          this.$emit('input', false)
        })
      } finally {
        this.handleClearFiles()
      }
    },
    handleClearFiles() {
      this.$refs.uploadForm.clearFiles()
      this.file = ''
      this.fileList = []
    },
    // 处理导入预览数据
    handleImportDatas(datas, code) {
      this.importDateData = Array.isArray(datas) && datas.map((item, index) => { return { ...item, index: index + 1 } })
      this.defaultList = this.importDateData
      if (code === 0) {
        this.uploadTableDateVisible = true
        this.file = ''
        this.fileList = []
      }
    },
    // 确定导入
    handleImport() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$refs.uploadForm.submit()
        }
      })
    },
    async _getLtReason() {
      const { datas } = await getLtReason()
      this.LtReasonOptions = datas
    },
    async _getDelayReason() {
      const { datas } = await getDelayReason()
      this.DelayReasonOptions = datas
    }

  }
}
</script>

<style scoped lang="scss">
.el-table .warning-row {
  color: red !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 8px; // 纵向滚动条 必写
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #dde;
    border-radius: 3px;
}
/deep/.el-dialog__body {
  padding:10px 20px!important;
}
/deep/ .el-table__header{
  height:30px!important;
}
/deep/  .el-table--medium th{

  padding:0!important;

}
</style>
