<template>
  <div>
    <el-dialog
      title="供应商交期确认"
      :visible.sync="visible"
      width="1100px"
      :close-on-click-modal="false"
      @open="open"
      @close="close"
    >

      <el-form ref="formRef" :model="form" label-width="80px">
        <div class="el-lt">
          <el-row :span="24">
            <el-col :span="8">
              <el-form-item label="采购订单" :class="$i18n.locale">
                <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="供应商" :class="$i18n.locale">
                <Select
                  v-model="form.vendorIdStr"
                  api-key="vendorList"
                  clearable
                  multiple
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下单日期">
                <el-date-picker
                  v-model="form.processProduceTime"
                  style="width:100%;"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="操作时间">
                <el-date-picker
                  v-model="form.operationTime"
                  style="width:100%;"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商反馈交货日期" :class="$i18n.locale" label-width="150px">
                <el-date-picker
                  v-model="form.deliveryDate"
                  style="width:100%;"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col class="row-center" :span="8">
              <el-button type="primary" @click="queryClick()">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <el-row :span="24" class="mb-3">
        <el-col :span="24">
          <el-button type="text" @click="dataBatch">批量填写日期</el-button>
          <el-button type="text" :loading="exportLoading" class="mr-2" @click="exportDetail">导出</el-button>
          <ImportFile
            ref="uploadPlanForm"
            v-model="uploadTablePlanVisible"
            :button-type="'text'"
            :upload-file="uploadPlanFile"
            :import-details-columns="deliveryConfirmColumns"
            :auto-upload="true"
            style="display: inline-block!important;"
            :details-data="importPlanData"
            :import-submit-loading="importSubmitLoading"
            @getFile="({file:val})=>file=val"
            @getSelectionChange="val=>dialogselectlist=val"
            @submitDetailDatas="submitPlanDatas"
          />
        </el-col>
      </el-row>

      <el-form ref="ruleForm" :model="ruleForm" :rules="ruleForm.rules">
        <Table
          ref="multipleTableRef"
          :table-data="ruleForm.tableData"
          :columns="deliveryConfirmationColumns"
          :check="true"
          :reserve-selection="true"
          :page-obj="pager"
          :show-overflow-tooltip="true"
          @select="select"
          @selectAll="selectAll"
          @handleSelectionChange="handleSelectionChange"
        >
          <el-table-column slot="deliveryDelayI18" label="延期原因类型" align="center" width="120" show-overflow-tooltip prop="deliveryDelayI18">
            <template slot-scope="scope">
              {{ scope.row.deliveryDelayI18 != 'null' ? scope.row.deliveryDelayI18 : '' }}
            </template>
          </el-table-column>
          <el-table-column slot="deliveryRemark" label="交期备注" align="center" width="100" show-overflow-tooltip prop="deliveryRemark">
            <template slot-scope="scope">
              {{ scope.row.deliveryRemark }}
            </template>
          </el-table-column>
          <el-table-column slot="purchaseDeliveryDate" label="采购反馈到货日期" align="center" width="160" prop="purchaseDeliveryDate">
            <template slot="header">
              <p><span style="color: red">*</span>采购反馈到货日期</p>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.purchaseDeliveryDate'"
                :rules="[{ required: scope.row.setRule, message: '必填', trigger: 'blur' }]"
              >
                <el-date-picker
                  v-model="scope.row.purchaseDeliveryDate"
                  type="date"
                  style="width:140px"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  @change="e => dataChange(e, scope.row)"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column slot="purchaseDeliveryDelay" label="未准交原因" align="center" width="160" prop="purchaseDeliveryDelay">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.purchaseDeliveryDelay'"
                :rules="[{ required: scope.row.setRule && scope.row.setDelayRule, message: '必填', trigger: 'change' }]"
              >
                <el-select v-model="scope.row.purchaseDeliveryDelay">
                  <el-option
                    v-for="item in _getAllCommodityDict('DELIVERY_DELAY_STATUS')"
                    :key="item.val"
                    :label="item.label"
                    :value="item.val"
                  />
                </el-select>

              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column slot="purchaseDeliveryRemark" label="交期备注" align="center" width="160" prop="purchaseDeliveryRemark">
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.purchaseDeliveryRemark'"
              >
                <el-input
                  v-model="scope.row.purchaseDeliveryRemark"
                  :maxlength="200"
                />

              </el-form-item>
            </template>
          </el-table-column>
        </Table>
        <div class="block">
          <Paging :pager="pager" end @pagination="pagerUpdate" /></div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="bthLoading" @click="reject">驳 回</el-button>
        <!-- <el-popconfirm
          title="该操作无法撤销，请仔细核对！确认or取消"
          @click="submit"
        >
          <el-button slot="reference" type="primary">确 定1</el-button>
        </el-popconfirm> -->
        <el-popover
          v-model="popoverVisible"
          placement="top"
          width="160"
          style="margin-left: 10px"
        >
          <p>该操作无法撤销，请仔细核对！确认or取消</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="popoverVisible = false">取消</el-button>
            <el-button :loading="bthLoading" type="primary" size="mini" @click="submit(),popoverVisible = false">确定</el-button>
          </div>
          <el-button slot="reference" :loading="bthLoading" type="primary">确 定</el-button>
        </el-popover>
      </span>
    </el-dialog>
    <el-dialog title="批量操作" :visible.sync="dialogFormVisible" width="500px" @close="dialogFormVisible = false,resetForm('dateFormRef')">
      <el-form
        ref="dateFormRef"
        :model="dateForm"
        :rules="rules"
      >
        <el-form-item
          label="交货日期批量"
          label-width="160px"
          prop="date"
        >
          <el-input-number v-model="dateForm.date" :precision="0" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false,resetForm('dateFormRef')">取 消</el-button>
        <el-button type="primary" @click="batchDelivery">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFeedbackPage, exportFeedback, feedbackImportView, feedbackImport, rejectFeedback, passFeedback } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { deliveryConfirmationColumns, deliveryConfirmColumns } from '@/constant/tablecolumns'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import Select from '@/components/Selection'
import ImportFile from '@/components/ImportFile'

import dayjs from 'dayjs'
// import { omit, cloneDeep } from 'lodash'
import { handleDownload } from '@/utils'

export default {
  components: { Paging, Table, Select, ImportFile },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    poList: {
      type: Array,
      default: () => {
        []
      }
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24
        }
      },
      rules: {
        date: [{ required: true, message: '必填', trigger: 'blur' }]
      },
      dateForm: {
        date: 0,
        email: ''
      },
      form: {
        purchaseOrderCode: '',
        vendorIdStr: [],
        processProduceTime: [],
        operationTime: [],
        deliveryDate: []
      },
      ruleForm: {
        tableData: [],
        rules: {
          // requestFundsAmount: [{ required: true, message: '必填', trigger: 'blur' }],
          // requestFundsChange: [{ required: true, message: '必填', trigger: 'change' }],
          // requestFalse: [{ required: false, message: '必填', trigger: 'blur' }]
        }
      },
      deliveryConfirmationColumns,
      deliveryConfirmColumns,
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      popoverVisible: false,
      TableLoading: false,
      exportLoading: false,
      multipleTable: [],
      file: '',
      fileList: [],
      uploadTablePlanVisible: false,
      dialogselectlist: [],
      importPlanData: [],
      importSubmitLoading: false,
      dialogFormVisible: false,
      bthLoading: false
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    // 生成对应的请求参数
    queryParameter() {
      const { purchaseOrderCode, vendorIdStr, processProduceTime, operationTime, deliveryDate } = this.form
      const [startProcessProduceTime, endProcessProduceTime] = processProduceTime || []
      const [startTime, endTime] = operationTime || []
      const [startDeliveryDate, endDeliveryDate] = deliveryDate || []
      return Object.assign({}, { startProcessProduceTime, endProcessProduceTime,
        startTime, endTime, startDeliveryDate, endDeliveryDate, purchaseOrderCode, vendorIdStr: vendorIdStr + ''
      }, this.pager)
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        // this._queryWarehouseList()
      } else {
        Object.assign(this.shortageCompletionForm, this.$options.data().shortageCompletionForm)
        this.$nextTick(() => {
          this.$refs.shortageCompletionForm.resetFields()
        })
      }
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    async submitPlanDatas() {
      try {
        this.importSubmitLoading = true
        await feedbackImport(this.dialogselectlist)
        this.$notify({
          message: '操作成功',
          type: 'success'
        })
        this.uploadTablePlanVisible = false
        this.queryClick()
      } finally {
        this.importSubmitLoading = false
      }
    },
    async uploadPlanFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { datas } = await feedbackImportView(form)
        this.importPlanData = datas
        this.uploadTablePlanVisible = true
        this.$notify({
          message: '操作成功',
          type: 'success'
        })
        this.file = ''
        this.fileList = []
      } finally {
        this.$refs.uploadPlanForm.clearFiles()
      }
    },
    async _getFeedbackPage() {
      try {
        this.TableLoading = true
        const params = Object.assign({}, this.queryParameter, this.pager)
        const { datas: { records, pager }} = await getFeedbackPage(params)
        this.ruleForm.tableData = records
        this.pager = pager
      } finally {
        this.TableLoading = false
        // this.clearSelection()
      }
    },
    submit() {
      // console.log('sdfs', this.$refs.multipleTableRef.$refs.table)
      const { multipleTable } = this
      if (Array.isArray(multipleTable) && !multipleTable.length) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return
      }
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          try {
            this.bthLoading = true
            const arr = []
            Array.isArray(multipleTable) && multipleTable.map(v => arr.push({
              id: v.id,
              purchaseDeliveryDate: v.purchaseDeliveryDate,
              purchaseDeliveryDelay: v.purchaseDeliveryDelay || '',
              purchaseDeliveryRemark: v.purchaseDeliveryRemark || ''
            }))
            const { code, msg } = await passFeedback(arr)
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.queryClick()
              this.clearSelection()
              this.resetForm('formRef')
              this.bthLoading = false
            }
          } finally {
            this.bthLoading = false
          }
        }
      })
    },
    getSelectionIdStr() {
      const { multipleTable } = this
      const arr = []
      Array.isArray(multipleTable) && multipleTable.map(v => arr.push(v.id))
      return arr + ''
    },
    async reject() {
      try {
        const { multipleTable } = this
        if (Array.isArray(multipleTable) && !multipleTable.length) {
          this.$message({
            message: '请至少选择一条数据',
            type: 'warning'
          })
          return
        }
        this.bthLoading = true
        const { code, msg } = await rejectFeedback({ idStr: this.getSelectionIdStr() })
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.queryClick()
          this.clearSelection()
        }
        this.bthLoading = false
      } finally {
        this.bthLoading = false
      }
    },
    selectAll(val) {
      if (!Array.isArray(val)) return
      val.length ? val.map(v => this.$set(v, 'setRule', true)) : this.ruleForm.tableData.map(v => this.$set(v, 'setRule', false))
    },
    select({ rows, row }) {
      const selected = rows.length && rows.indexOf(row) !== -1
      this.$set(row, 'setRule', selected)
    },
    pagerUpdate(val) {
      this.pager = val
      this._getFeedbackPage()
    },
    handleSelectionChange(val) {
      this.multipleTable = val
    },
    open() {
      this._getFeedbackPage()
    },
    queryClick(page) {
      this.pager.current = 1
      this._getFeedbackPage()
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    },
    async exportDetail() {
      try {
        this.exportLoading = true
        const { multipleTable } = this
        const params = {}
        if (multipleTable.length) {
          Object.assign(params, { idStr: this.getSelectionIdStr() })
        } else {
          Object.assign(params, this.queryParameter)
        }
        const data = await exportFeedback(params)
        handleDownload(data, '供应商交期确认.xlsx')
        this.exportLoading = false
      } finally {
        this.exportLoading = false
        // this.clearSelection()
      }
    },
    clearSelection() {
      this.$nextTick(() => {
        this.$refs.multipleTableRef.$refs.table.clearSelection()
        // for (var i = 0; i < this.$refs.multipleTableRef.length; i++) {
        //   this.$refs.multipleTableRef[i].clearSelection()
        // }
      })
    },
    dataChange(e, row) {
      const { planDeliveryDate } = row
      if (e && planDeliveryDate) {
        const day = dayjs(e).diff(planDeliveryDate, 'day')
        // console.log('%c 🥦 day: ', 'font-size:20px;background-color: #F5CE50;color:#fff;', day)
        this.$set(row, 'setDelayRule', day > 0 || day < -10)
      }
    },
    batchDelivery() {
      this.$refs.dateFormRef.validate(async valid => {
        if (valid) {
          try {
            if (this.multipleTable.some(item => dayjs().diff(dayjs(item.deliveryDate).add(this.dateForm.date, 'day').format('YYYY-MM-DD'), 'day') > 0)) {
              this.$message({
                message: '采购反馈到货日期不可小于当前日期',
                type: 'warning'
              })
              return
            } else {
              this.multipleTable.map(item => {
                this.$set(item, 'purchaseDeliveryDate', dayjs(item.deliveryDate).add(this.dateForm.date, 'day').format('YYYY-MM-DD'))
              })
              this.dialogFormVisible = false
              this.$message({
                message: '批量添加成功',
                type: 'success'
              })
              this.resetForm('dateFormRef')
            }
          } finally {
            // console.log('sdfs', this.ruleForm.tableData)
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    dataBatch() {
      const { multipleTable } = this
      if (Array.isArray(multipleTable) && !multipleTable.length) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return
      }
      this.dialogFormVisible = true
    },
    close() {
      this.form = this.$options.data.call(this).form
      this.clearSelection()
      this.$emit('input', false)
      this.ruleForm.tableData = []
      // 刷新外面的列表页和角标
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped lang="scss">

/deep/.el-select {
            width: 100%  !important;
        }

</style>
