<template>
  <div>
    <el-dialog
      title="缺量收货"
      :visible.sync="visible"
      width="500px"
      :close-on-click-modal="false"
    >

      <el-form ref="shortageCompletionForm" :model="shortageCompletionForm" label-width="100px">
        <el-form-item label="采购订单号">
          <el-row v-for="(item,index) in poList" :key="index">
            <el-col>{{ item.purchaseOrderCode }}  ({{ item.groupStyle }} - {{ item.shortageReceiptQuantity }})</el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="缺量原因" required :rules="{required: true, message:'必填', trigger: 'blur' }" prop="ullageReason">
          <el-select v-model="shortageCompletionForm.ullageReason">
            <el-option
              v-for="item in _getAllCommodityDict('PURCHASE_ORDER_SHORTAGE_REASON')"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="shortageCompletionForm.ullageReason+''=='9'" label="备注" required :rules="{required: true, message:'必填', trigger: 'blur' }" prop="ullageRemark">
          <el-input
            v-model="shortageCompletionForm.ullageRemark"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input',false)">取 消</el-button>
        <el-button type="primary" :disabled="shortageCompletionDialogVisibleDiable" @click="batchCompletionDialogSure">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { shortageCompletion } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    poList: {
      type: Array,
      default: () => {
        []
      }
    }
  },
  data() {
    return {
      shortageCompletionForm: {
        ullageRemark: '',
        ullageReason: ''
      },
      shortageCompletionDialogVisibleDiable: false
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    shortageCompletionFormParams() {
      const arr = []
      this.poList.map(item => {
        arr.push(item.purchaseOrderCode)
      })
      const { ullageReason, ullageRemark } = this.shortageCompletionForm
      if (ullageReason + '' === '9') {
        return Object.assign({}, { ullageReason, ullageRemark }, { purchaseOrderCode: arr.toString() })
      } else {
        return Object.assign({}, { ullageReason }, { purchaseOrderCode: arr.toString() })
      }
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        // this._queryWarehouseList()
      } else {
        Object.assign(this.shortageCompletionForm, this.$options.data().shortageCompletionForm)
        this.$nextTick(() => {
          this.$refs.shortageCompletionForm.resetFields()
        })
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    batchCompletionDialogSure() {
      this.$refs.shortageCompletionForm.validate(async valid => {
        if (valid) {
          try {
            this.shortageCompletionDialogVisibleDiable = true
            const { code, msg } = await shortageCompletion(this.shortageCompletionFormParams)
            if (code === 0) {
              this.$notify({
                message: msg,
                type: 'success'
              })
              this.$emit('input', false)
              this.$emit('refresh')
            }
          } finally {
            this.shortageCompletionDialogVisibleDiable = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

/deep/.el-select {
            width: 100%  !important;
        }
</style>
